import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import { endNavigate } from "../../redux/actions/navigation";

import styles from "./GeneratePdfScreen.module.css";
import { getDraws, sendEmailPDf } from "../../redux/actions/draw";
import Input from "../../components/Input/Input";
import DropdownDraw from "../../components/DropdownDraw/DropdownDraw";
import{getTotalBoulcashPlayedByDraw, validateEmail} from "../../constants/Utils";
import { getDetailsDraws } from "../../redux/actions/draw";



class GeneratePdfScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dateOfDraw: "",
      startIndexPdf: "",
      endIndexPdf: "",
      email: "",
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = async() => {
    await this.props.dispatch(getDraws());
    await this.props.dispatch(getDetailsDraws());
  };

  handleCheckOnChange = (e) => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked });
  };

  handleDropdownChange = (e, { value }) => {
    e.preventDefault();
    this.setState({ dateOfDraw: value });
  };
  handleInputOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    return this.setState({ [name]: value });
  };
  handleSentMail = async () => {
    const { dateOfDraw, startIndexPdf, endIndexPdf, email } = this.state;
    await this.props.dispatch(sendEmailPDf(dateOfDraw, startIndexPdf, endIndexPdf, email));
  };
  isDisabled =  () => {
    const{email,dateOfDraw,endIndexPdf,startIndexPdf}= this.state;
    let result = email !== "" && dateOfDraw !== "" && endIndexPdf !== "" && startIndexPdf !== "";
    return result ;
  };

  render() {
    const {draw, navigation, location } = this.props;
    const { email, dateOfDraw, startIndexPdf, endIndexPdf} = this.state;
    
   

    if (navigation.navigate && location.pathname !== navigation.route) {
      return <Redirect push to={navigation.route} />;
    }

    return (
      <Container title={getLabel("MailPdf")}>
         <div className={styles.text}>Nombres Joueurs : {getTotalBoulcashPlayedByDraw(dateOfDraw??"",draw?.detailsdraws?.responseF ?? [])}</div>
         <div className={styles.inputsContainer}>
          <Input
            name={"startIndexPdf"}
            type={"number"}
            value={startIndexPdf}
            placeholder={getLabel("StartIndex")}
            onChange={this.handleInputOnChange}
          />
          <Input
            name={"endIndexPdf"}
            type={"number"}
            value={endIndexPdf}
            placeholder={getLabel("EndIndex")}
            onChange={this.handleInputOnChange}
          />
          <Input
            icon={"mail"}
            name={"email"}
            value={email}
            placeholder={getLabel("Email")}
            onChange={this.handleInputOnChange}
          />
          <DropdownDraw
            options={draw.draws}
            icon={"calendar"}
            placeholder={getLabel("Draw")}
            value={dateOfDraw}
            onChange={this.handleDropdownChange}
          />
        </div>
        <div className={styles.buttonsContainer}>
					{this.isDisabled() && validateEmail(this.state.email) &&<Button className={styles.button} onClick={this.handleSentMail}>
						<div className={styles.text}>{getLabel("MailPdf")}</div>
					</Button>}
				</div>
       
      </Container>
    );
  }
}

function mapStateToProps({ user, draw, city, navigation }) {
  return { user, draw,city, navigation };
}

export default withRouter(connect(mapStateToProps)(GeneratePdfScreen));
