import React, { PureComponent } from "react";
import { getLabel } from "../../constants/Strings";
import Countdown from "react-countdown-now";

import styles from "./Countdown.module.css";

class CountDown extends PureComponent {
	render() {
		const { until, onComplete } = this.props;
		const renderer = ({ days, hours, minutes, seconds }) => {
			return (
				<div className={styles.contdownContainer}>
					<div className={styles.timerContainer}>
						<div className={styles.timerBox}>{days < 10 ? "0" + days : days}</div>
						<label className={styles.timerLabel}>{getLabel("Days")}</label>
					</div>
					<div className={styles.timerContainer}>
						<div className={styles.timerBox}>{hours < 10 ? "0" + hours : hours}</div>
						<label className={styles.timerLabel}>{getLabel("Hours")}</label>
					</div>
					<div className={styles.timerContainer}>
						<div className={styles.timerBox}>{minutes < 10 ? "0" + minutes : minutes}</div>
						<label className={styles.timerLabel}>{getLabel("Minutes")}</label>
					</div>
					<div className={styles.timerContainer}>
						<div className={styles.timerBox}>{seconds < 10 ? "0" + seconds : seconds}</div>
						<label className={styles.timerLabel}>{getLabel("Seconds")}</label>
					</div>
				</div>
			);
		};
		return <Countdown date={until} onComplete={onComplete} renderer={renderer} />;
	}
}

export default CountDown;
