import React, { PureComponent } from "react";
import { Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import { getTerms } from "../../constants/PrivacyPolicy";
import styles from "./PrivacyPolicy.module.css";

class PrivacyPolicy extends PureComponent {
	render() {
		const { visible, onClose, settings } = this.props;
		return (
			<Modal open={visible} onClose={onClose}>
				<Modal.Header className={styles.header}>{getLabel("PrivacyPolicy")}</Modal.Header>
				<Modal.Content>
					<p className={styles.text}>{getTerms(settings.language)}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button fluid color={"red"} onClick={onClose}>
						{getLabel("Done")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

function mapStateToProps({ settings }) {
	return { settings };
}

export default connect(mapStateToProps)(PrivacyPolicy);
