import React, { PureComponent } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { getLabel } from "../../constants/Strings";

import Prizes from "../Prizes/Prizes";
import Boulpik from "../Boulpik/Boulpik";
import DetailDraw from "../DetailDraw/DetailDraw";
import InvestorDetail from "../InvestorsDetail/InvestorDetail";

import styles from "./Draws.module.css";

class Draws extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      expanded: true,
      childExpanded: false,
    };
  }

  handlePress = (id) => {
    if (this.state.value === id) {
      return this.setState({ value: id, expanded: !this.state.expanded });
    }
    return this.setState({ value: id, expanded: true });
  };

  handleChildPress = () => {
    return this.setState({ childExpanded: !this.state.childExpanded });
  };

  renderBoulpiks = (boulpiks) => {
    const boulpik = boulpiks.map((item, index) => {
      return <Boulpik key={index} boulpik={item?.boulpik} counter={item?.contador} />;
    });
    return boulpik;
  };

  renderDetailDraw = (item) => {
    return <DetailDraw item={item} />;
  };

  renderInvestorsDetail= (item) => {
    return <InvestorDetail item={item} />;
  };

  renderPrizes = (prizes) => {
    const prize = prizes.map((item, index) => {
      return <Prizes key={index} placement={item.place} prize={item.montant} boulpik={item.boulpik} winners={item.winners} />;
    });
    return prize;
  };

  render() {
    const { data, type, onPress } = this.props;
    console.log("DATA : ",data);
    const { expanded, value, childExpanded } = this.state;
    const Draws = data?.map((item, index) => {
      
      if (type === "boulpik") {
        return (
          <Accordion key={index} className={styles.container}>
            <Accordion.Title
              active={expanded && value === index}
              onClick={() => this.handlePress(index)}
              className={expanded && value === index ? styles.expandedTitleText : styles.titleText}>
              <div className={styles.title}>{getLabel("Draw") + ": " + item.fecha}</div>
              <Icon name={expanded && value === index ? "chevron down" : "chevron up"} className={styles.titleIcon} />
            </Accordion.Title>
            <Accordion.Content active={expanded && value === index} className={styles.contentContainer}>
              {this.renderBoulpiks(item.arrayBoulpik)}
            </Accordion.Content>
          </Accordion>
        );
      } else if (type === "prizes") {
        return (
          <Accordion key={index} className={styles.container}>
            <Accordion.Title
              active={expanded && value === index}
              onClick={() => this.handlePress(index)}
              className={expanded && value === index ? styles.expandedTitleText : styles.titleText}>
              <div className={styles.title}>{getLabel("Draw") + ": " + item.end}</div>
              <Icon name={expanded && value === index ? "chevron down" : "chevron up"} className={styles.titleIcon} />
            </Accordion.Title>
            <Accordion.Content active={expanded && value === index} className={styles.contentContainer}>
              {this.renderPrizes(item.arrayWinner)}
              <Accordion className={styles.container} style={isEmpty(item.userBoulpik) ? { display: "none" } : { display: "block" }}>
                <Accordion.Title active={childExpanded} onClick={this.handleChildPress} className={childExpanded ? styles.expandedTitleText : styles.titleText}>
                  <div className={styles.title}>{getLabel("BoulpiksPlayed")}</div>
                  <Icon name={childExpanded ? "chevron down" : "chevron up"} className={styles.titleIcon} />
                </Accordion.Title>
                <Accordion.Content active={childExpanded} className={styles.contentContainer}>
                  {this.renderBoulpiks(item.userBoulpik)}
                </Accordion.Content>
              </Accordion>
            </Accordion.Content>
          </Accordion>
        );
      } else if (type === "details") {
        return (
          <Accordion key={index} className={styles.container}>
            <Accordion.Title
              active={expanded && value === index}
              onClick={() => this.handlePress(index)}
              className={expanded && value === index ? styles.expandedTitleText : styles.titleText}>
              <div className={styles.title}>{getLabel("Draw") + ": " + item.end}</div>
              <Icon name={expanded && value === index ? "chevron down" : "chevron up"} className={styles.titleIcon} />
            </Accordion.Title>
            <Accordion.Content active={expanded && value === index} className={styles.contentContainer}>
              {this.renderDetailDraw(item, item.end)}
            </Accordion.Content>
          </Accordion>
        );
       } else if (type === "investors") { 
        return (
          <Accordion key={index} className={styles.container}>
            <Accordion.Title
              active={expanded && value === index}
              onClick={() => this.handlePress(index)}
              className={expanded && value === index ? styles.expandedTitleText : styles.titleText}>
              <div className={styles.title}>{item?.nom}</div>
              <Icon name={expanded && value === index ? "chevron down" : "chevron up"} className={styles.titleIcon} />
            </Accordion.Title>
            <Accordion.Content active={expanded && value === index} className={styles.contentContainer}>
              {this.renderInvestorsDetail(item)}
            </Accordion.Content>
          </Accordion>
        );

       }else if(item?.end ===data?.[0]?.end){
          return (
            <div key={index} className={index === 0 ? styles.redNoListContainer : styles.noListContainer} onClick={item?.end ===data?.[0]?.end ? () => onPress(item?.end, item?.price) : undefined }>
              <div className={index === 0 ? styles.expandedTitleText : styles.titleText}>{getLabel("Draw") + ": " + item?.end}</div>
            </div>
          );
      }else{
        return (<div></div>);
      }
      
       
      
      // if (type === "prizes") {
      // 	return (
      // 		<List.Accordion
      // 			key={index}
      // 			style={expanded && value === index ? styles.expandedContainer : styles.container}
      // 			titleStyle={expanded && value === index ? styles.expandedTitleText : styles.titleText}
      // 			expanded={expanded && value === index}
      // 			onPress={() => this.handlePress(index)}
      // 			title={getLabel("Draw") + ": " + item.end}
      // 		>
      // 			<View style={styles.contentContainer}>
      // 				{this.renderPrizes(item.arrayWinner)}
      // 				<List.Accordion
      // 					style={
      // 						isEmpty(item.userBoulpik)
      // 							? { display: "none" }
      // 							: childExpanded
      // 							? styles.expandedContainer
      // 							: styles.container
      // 					}
      // 					titleStyle={childExpanded ? styles.expandedTitleText : styles.titleText}
      // 					expanded={childExpanded}
      // 					onPress={this.handleChildPress}
      // 					title={"Boulpiks Played"}
      // 				>
      // 					<View style={isEmpty(item.userBoulpik) ? { display: "none" } : styles.childContentContainer}>
      // 						{this.renderBoulpiks(item.userBoulpik)}
      // 					</View>
      // 				</List.Accordion>
      // 			</View>
      // 		</List.Accordion>
      // 	);
      // }
    });
    return Draws;
  }
}

export default Draws;
