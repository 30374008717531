import React, { PureComponent } from "react";
import { Icon, Select } from "semantic-ui-react";
import { isEmpty } from "lodash";

import styles from "./DropdownDraw.module.css";

class DropdownDraw extends PureComponent {
	mapOptions = options => {
		if (!isEmpty(options)) {
			const mappedOptions = options.map(item => {
				return { text: item.end, value: item.end };
			});
			return mappedOptions;
		} else {
			return [];
		}
	};

	render() {
		const { name, options, value, onChange, icon, placeholder, disabled, style } = this.props;

		return (
			<div className={styles.inputContainer} style={style}>
				<Icon name={icon} className={styles.icon} />
				<Select
					disabled={disabled}
					name={name}
					options={this.mapOptions(options)}
					onChange={onChange}
					value={value}
					placeholder={placeholder}
					className={styles.dropdown}
				/>
			</div>
		);
	}
}

export default DropdownDraw;
