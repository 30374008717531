import React, { PureComponent } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";

class Loading extends PureComponent {
	render() {
		const { isFetching } = this.props;
		return (
			<Dimmer page active={isFetching}>
				<Loader size="huge">{getLabel("Loading")}...</Loader>
			</Dimmer>
		);
	}
}

export default Loading;
