import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, SHARE_PERCENT } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";

function requestSuccess(type, data) {
	return { type, payload: { data } };
}

function requestFail(errorCode, language) {
	return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getSharePercent() {
	return (dispatch) => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.get("/getSharePercent")
			.then((response) => {
				if (response.data.success) {
					dispatch(requestSuccess(SHARE_PERCENT, response.data.data));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch((error) => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}
