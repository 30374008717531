import React, { PureComponent } from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import styles from "./SuccessDialog.module.css";

class SuccessDialog extends PureComponent {
	render() {
		const { success, title, text, onPress } = this.props;
		return (
			<Modal open={success} onClose={onPress}>
				<Modal.Content className={styles.content}>
					<Icon name={"check circle"} size={"big"} color={"red"} />
					<div className={styles.titleText}>{title}</div>
					<p className={styles.text}>{text}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button fluid onClick={onPress} color={"red"}>
						{getLabel("Done")}
					</Button>
				</Modal.Actions>
			</Modal>
		);
	}
}

export default SuccessDialog;
