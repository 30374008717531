import React, { PureComponent } from "react";
import { withRouter, Switch } from "react-router-dom";
import { Dimmer, Modal, Button } from "semantic-ui-react";
import { isIOS } from "react-device-detect";
import PWAPrompt from "react-ios-pwa-prompt";
import { getLabel } from "./constants/Strings";
import io from "socket.io-client";
import config from "./config";
import Cookie from "react-cookies";

//Redux
import { connect } from "react-redux";
import { logout, rehydrate, refreshCredit } from "./redux/actions/user";
import { importSettings } from "./redux/actions/settings";
import { dismissErrors } from "./redux/actions/error";
import { dismissSuccess } from "./redux/actions/success";
import { newTransaction } from "./redux/actions/transactions";
//Components
import IdleTimer from "react-idle-timer";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Loading from "./components/Loading/Loading";
import ErrorDialog from "./components/ErrorDialog/ErrorDialog";
import SuccessDialog from "./components/SuccessDialog/SuccessDialog";
//Screens
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen/ForgotPasswordScreen";
import SignUpScreen from "./screens/SignUpScreen/SignUpScreen";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import BoulpikScreen from "./screens/BoulpikScreen/BoulpikScreen";
import TransactionsScreen from "./screens/TransactionsScreen/TransactionsScreen";
import HistoryScreen from "./screens/HistoryScreen/HistoryScreen";
import SettingsScreen from "./screens/SettingsScreen/SettingsScreen";


import CartScreen from "./screens/CartScreen/CartScreen";
import ChooseDrawScreen from "./screens/ChooseDrawScreen/ChooseDrawScreen";
import CreateBoulpikScreen from "./screens/CreateBoulpikScreen/CreateBoulpikScreen";
import RechargeScreen from "./screens/RechargeScreen/RechargeScreen";
import WithdrawScreen from "./screens/WithdrawScreen/WithdrawScreen";
import TransferCreditScreen from "./screens/TranferCreditScreen/TransferCreditSreen";
import AuthorizedDealersScreen from "./screens/AuthorizedDealersScreen/AuthorizedDealersScreen";
import CreateDealerScreen from "./screens/CreateDealerScreen/CreateDealerScreen";
import CreateSellerScreen from "./screens/CreateSellerScreen/CreateSellerScreen";
import CreateAdminScreen from "./screens/CreateAdminScreen/CreateAdminScreen";
import NextDrawScreen from "./screens/NextDrawScreen/NextDrawScreen";
import CreateDrawScreen from "./screens/CreateDrawScreen/CreateDrawScreen";
import DrawDetailsScreen from "./screens/DrawDetailsScreen/DrawDetailsScreen";
import UpdateDrawScreen from "./screens/UpdateDrawScreen/UpdateDrawScreen";
import TransitionScreen from "./screens/TransitionScreen/TransitionScreen";
import SignUpSellerScreen from "./screens/SignUpSellerScreen/SignUpSellerScreen";
import SalesScreen from "./screens/SalesScreen/SalesScreen";
import HelpScreen from "./screens/HelpScreen/HelpScreen";
import CreateInvestorScreen from "./screens/CreateInvestorScreen/CreateInvestorScreen";
import ShareScreen from "./screens/ShareScreen/ShareScreen";
import BenefitScreen from "./screens/BenefitScreen/BenefitScreen";
import SeeInvestorScreen from "./screens/SeeInvestorScreen/SeeInvestorScreen";
import GeneratePdfScreen from "./screens/GeneratePdfScreen/GeneratePdfScreen";
import SetWinnersScreen from "./screens/SetWinnersScreen/SetWinnersScreen";
import WinnerDetailScreen from "./screens/WinnerDetailScreen/WinnerDetailScreen";


class App extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			installModalIOS: false,
			installModalAndroid: false,
		};

		this.props.dispatch(importSettings());
		this.props.dispatch(rehydrate());
		window.scrollTo(0, 0);
		this.installPrompt = null;
	}

	componentDidMount = async () => {
		if (isIOS && (!window.matchMedia("(display-mode: standalone)").matches || !window.navigator.standalone)) {
			//console.log("Opened on IOS");
			this.setState({ installModalIOS: true });
		} else {
			var cookie = await Cookie.load("visited");
			if (cookie === undefined) {
				//console.log("Opened on Android or Desktop");
				Cookie.save("visited", { visited: true }, { path: "/", maxAge: 3600 });
				window.addEventListener("beforeinstallprompt", (e) => {
					// See if the app is already installed, in that case, do nothing
					if (
						(window.matchMedia && window.matchMedia("(display-mode: standalone)").matches) ||
						window.navigator.standalone === true
					) {
						return false;
					}
					this.installPrompt = e;
					this.setState({ installModalAndroid: true });
				});
			}
		}
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn) {
			const socket = io.connect(config.api_base_url + `?userId=${this.props.user._id}`);
			global.socket = socket;
			//Connect Event
			global.socket.on("connect", (message) => {
				//console.log("Connected To Socket");
			});
			//Update Transaction Event
			global.socket.on("updateTransaction", (transaction) => {
				//console.log("updateTransaction");
				this.props.dispatch(newTransaction(transaction));
			});

			global.socket.on("receiveBalance", (credit) => {
				//console.log("receiveBalance");
				this.props.dispatch(refreshCredit(credit));
			});
		}
	};

	onIdle = () => {
		if (this.props.isLoggedIn) {
			return this.props.dispatch(logout());
		}
	};

	closeIOSModal = () => {
		this.setState({ installModalIOS: false });
	};

	closeAndroidModal = () => {
		this.setState({ installModalAndroid: false });
	};

	handleInstallApp = async () => {
		this.installPrompt.prompt();
		const { outcome } = await this.installPrompt.userChoice;
		if (outcome === "accepted") {
			//console.log("Installed");
			this.setState({ installModalAndroid: false });
		} else {
			//console.log("Not Installed");
			this.setState({ installModalAndroid: false });
		}
	};

	handleDismissErrors = (e) => {
		e.preventDefault();
		this.props.dispatch(dismissErrors());
	};

	handleDismissSuccess = (e) => {
		e.preventDefault();
		this.props.dispatch(dismissSuccess());
	};

	render() {
		const { loading, isLoggedIn, error, success, user } = this.props;
		const { installModalAndroid, installModalIOS } = this.state;

		if (user._id !== undefined) {
			return (
				<Dimmer.Dimmable blurring dimmed={loading.isFetching || error.error || success.success}>
					<Modal open={installModalAndroid} onClose={this.closeAndroidModal}>
						<Modal.Content>
							<h3>{getLabel("PromptTitle")}</h3>
							<p>{getLabel("PromptBody")}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button fluid onClick={this.handleInstallApp} color={"green"}>
								{getLabel("Install")}
							</Button>
						</Modal.Actions>
					</Modal>
					<Modal open={installModalIOS} onClose={this.closeIOSModal}>
						<PWAPrompt
							timesToShow={5}
							delay={0}
							permanentlyHideOnDismiss={false}
							copyTitle={getLabel("PromptTitle")}
							copyBody={getLabel("PromptBody")}
							copyShareButtonLabel={getLabel("Prompt1")}
							copyAddHomeButtonLabel={getLabel("Prompt2")}
							copyClosePrompt={getLabel("Cancel")}
						/>
					</Modal>
					<Loading isFetching={loading.isFetching} />
					<ErrorDialog
						error={error.error}
						title={error.errorTitle}
						text={error.errorText}
						onPress={this.handleDismissErrors}
					/>
					<SuccessDialog
						success={success.success}
						title={success.successTitle}
						text={success.successText}
						onPress={this.handleDismissSuccess}
					/>
					<IdleTimer
						ref={(ref) => {
							this.idleTimer = ref;
						}}
						element={document}
						onIdle={this.onIdle}
						timeout={1000 * 60 * 15}
					/>
					<Switch>
						<PublicRoute isLoggedIn={isLoggedIn} role={user.role} exact path={"/"} component={LoginScreen} />
						<PublicRoute
							isLoggedIn={isLoggedIn}
							role={user.role}
							exact
							path={"/register"}
							component={TransitionScreen}
						/>
						<PublicRoute isLoggedIn={isLoggedIn} role={user.role} exact path={"/sign-up"} component={SignUpScreen} />
						<PublicRoute
							isLoggedIn={isLoggedIn}
							role={user.role}
							exact
							path={"/sign-up-seller"}
							component={SignUpSellerScreen}
						/>
						<PublicRoute
							isLoggedIn={isLoggedIn}
							role={user.role}
							exact
							path={"/forgot-password"}
							component={ForgotPasswordScreen}
						/>
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/weekly-prizes"} component={HomeScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/my-boulpik"} component={BoulpikScreen} />
						<ProtectedRoute
							isLoggedIn={isLoggedIn}
							exact
							path={"/my-boulpik/choose-draw"}
							component={ChooseDrawScreen}
						/>
						<ProtectedRoute
							isLoggedIn={isLoggedIn}
							exact
							path={"/my-boulpik/choose-draw/boulpik"}
							component={CreateBoulpikScreen}
						/>
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/history"} component={HistoryScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/settings"} component={SettingsScreen} />

						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/transactions"} component={TransactionsScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/draws-schedule"} component={NextDrawScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/cart"} component={CartScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/recharge"} component={RechargeScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/withdrawal"} component={WithdrawScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/transfer-credit"} component={TransferCreditScreen} />
						<ProtectedRoute
							isLoggedIn={isLoggedIn}
							exact
							path={"/authorized-dealers"}
							component={AuthorizedDealersScreen}
						/>
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/create-dealer"} component={CreateDealerScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/create-seller"} component={CreateSellerScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/create-admin"} component={CreateAdminScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/create-investor"} component={CreateInvestorScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/create-draw"} component={CreateDrawScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/detail-draw"} component={DrawDetailsScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/sales"} component={SalesScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/help"} component={HelpScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/share"} component={ShareScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/benefit"} component={BenefitScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/see-investor"} component={SeeInvestorScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/print-pdf"} component={GeneratePdfScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/update-draw"} component={UpdateDrawScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/set-winners"} component={SetWinnersScreen} />
						<ProtectedRoute isLoggedIn={isLoggedIn} exact path={"/winner-details"} component={WinnerDetailScreen} />

						
						
						
					</Switch>
				</Dimmer.Dimmable>
			);
		} else {
			return (
				<Dimmer.Dimmable blurring dimmed={true}>
					<Loading isFetching={true} />
				</Dimmer.Dimmable>
			);
		}
	}
}

function mapStateToProps({ loading, user, error, success }) {
	return { loading, error, success, user, isLoggedIn: user._id !== null };
}

export default withRouter(connect(mapStateToProps)(App));
