import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Table, Pagination, Icon } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { currencyFormat, dateFormat } from "../../constants/Utils";
import { isEmpty } from "lodash";
import { isSmallDevice } from "../../constants/Layout";
import { endNavigate } from "../../redux/actions/navigation";
import { getTransactions } from "../../redux/actions/transactions";
import Container from "../../components/Container/Container";

import styles from "./TransactionsScreen.module.css";

class TransactionsScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allItems: [],
      currentItems: [],
      totalPages: 0,
      activePage: 1,
      pageLimit: 10,
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = async () => {
    await this.props.dispatch(getTransactions());
    const allItems = this.props.transactions.transactions;
    const totalPages = Math.ceil(allItems.length / this.state.pageLimit);
    const currentItems = allItems.slice(0, this.state.pageLimit);
    this.setState({ allItems, currentItems, totalPages });
  };

  componentDidUpdate = () => {
    if (this.state.allItems !== this.props.transactions.transactions) {
      const allItems = this.props.transactions.transactions;
      const totalPages = Math.ceil(allItems.length / this.state.pageLimit);
      const currentItems = allItems.slice(0, this.state.pageLimit);
      this.setState({ allItems, currentItems, totalPages });
    }
  };

  onPageChanged = (e, data) => {
    e.preventDefault();
    const { allItems, pageLimit } = this.state;
    const { activePage } = data;
    const offset = (activePage - 1) * pageLimit;
    const currentItems = allItems.slice(offset, offset + pageLimit);
    this.setState({ activePage, currentItems });
  };

  renderItemCount = (itemRange, currentItems, totalItems) => {
    if (currentItems.length <= 1) {
      return (
        <h1 className={styles.itemCount}>
          {getLabel("Items")}: {itemRange + currentItems.length}/{totalItems}
        </h1>
      );
    } else {
      return (
        <h1 className={styles.itemCount}>
          {getLabel("Items")}: {itemRange + 1}-{itemRange + currentItems.length}/{totalItems}
        </h1>
      );
    }
  };

  renderTableRows = (data) => {
    if (!isEmpty(data)) {
      const rows = data.map((item, index) => {
        const recipient = this.props.user._id === item.idreceveur ? item.envoyeur : item.receveur;

        const moneyFlow = this.props.user._id === item.idreceveur ? "positive" : "negative";
        const moneyFlowSign = this.props.user._id === item.idreceveur ? "" : "-";
        return (
          <Table.Row
            key={index}
            style={
              moneyFlow === "positive"
                ? { backgroundColor: "rgba(0, 179, 60, 0.2)" }
                : { backgroundColor: "rgba(206, 29, 26, 0.2)" }
            }>
            <Table.Cell className={styles.text} width={3}>
              {item.genre}
            </Table.Cell>
            <Table.Cell className={styles.text} width={5}>
              {recipient !== "" && recipient !== undefined ? recipient : getLabel("System")}
            </Table.Cell>
            <Table.Cell className={styles.text} width={3}>
              {dateFormat(item.created)}
            </Table.Cell>
            <Table.Cell className={styles.text} width={5}>
              {moneyFlowSign + currencyFormat(item.balance)}
            </Table.Cell>
          </Table.Row>
        );
      });
      return rows;
    }
    return null;
  };

  render() {
    const { allItems, currentItems, activePage, totalPages, pageLimit } = this.state;
    const itemRange = (activePage - 1) * pageLimit;
    const totalItems = allItems.length;

    return (
      <Container title={getLabel("MyTransactions")}>
        <Table compact celled unstackable size={"small"} columns={16}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={styles.titleText} width={3}>
                {getLabel("Type")}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.titleText} width={5}>
                {getLabel("Recipient")}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.titleText} width={3}>
                {getLabel("Date")}
              </Table.HeaderCell>
              <Table.HeaderCell className={styles.titleText} width={5}>
                {getLabel("Balance")}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{this.renderTableRows(currentItems)}</Table.Body>
        </Table>
        <div className={isSmallDevice ? styles.smallPagination : styles.pagination}>
          <Pagination
            activePage={activePage}
            ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={totalPages}
            onPageChange={this.onPageChanged}
          />
          {this.renderItemCount(itemRange, currentItems, totalItems)}
        </div>
      </Container>
    );
  }
}

function mapStateToProps({ user, transactions, navigation }) {
  return { user, transactions, navigation };
}

export default connect(mapStateToProps)(TransactionsScreen);
