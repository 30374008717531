import store from "../redux/store";

export function currencyFormat(num) {
  if (num !== undefined && typeof num === "string") {
    const newNum = parseFloat(num);
    return newNum.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " HTG";
  } else if (num === null) {
    return "";
  } else if (num !== undefined && num !== null) return num.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " HTG";
}

export function splitNumbers(string) {
  const stringArray = string.split("");
  return stringArray;
}

export function phoneFormat(string) {
  const cleanString = string.replace(/[^\d]/g, "");
  if (cleanString.length === 10) {
    return cleanString.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
  } else if (cleanString.length === 11) {
    return cleanString.replace(/(\d{3})(\d{2})(\d{2})(\d{4})/, "($1)$2 $3 $4");
  }
  return string;
}

export function getTotalBoulcashPlayedByDraw(strDraw, Draw ){
  let length = 0;
    for(let i=0; i<Draw.length; i++){
      if(Draw[i].fecha === strDraw){
        length = Draw[i].totalBoulcahPlayed;
      }
    }
  return length;
}

export function dateFormat(date) {
  const today = new Date(date);
  const dd = today.getDate();
  const mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  return dd + "/" + mm + "/" + yyyy;
}

export function shareDetail(obj) {
  var totalBoulpik = 0;
  for (let i = 0; i < obj.length; i++) {
    totalBoulpik += obj[i].price * obj[i].total;
  }

  return totalBoulpik / 100;
}

export function getCurrentLanguage() {
  const state = store.getState();
  const language = state.settings.language;
  return language;
}

export function getCurrentUserRole() {
  const state = store.getState();
  const role = state.user.role;

  return role;
}
export function validateEmail (email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
