import React, { PureComponent } from "react";
import { Icon, Image } from "semantic-ui-react";
import { currencyFormat, splitNumbers } from "../../constants/Utils";
import { getLabel } from "../../constants/Strings";

import styles from "./Prizes.module.css";

class Prizes extends PureComponent {
  renderBoulpiks = (numbers) => {
    const items = splitNumbers(numbers);
    const boulpiks = items.map((item, index) => {
      return (
        <div className={styles.boulpiks} key={index}>
          <div className={styles.boulpikText}>{item}</div>
        </div>
      );
    });
    return boulpiks;
  };

  renderNames = (array) => {
    const names = array.reduce((concatString, current, index, source) => {
      if (index + 1 === source.length) {
        return concatString + current.nom;
      } else {
        return concatString + current.nom + ", ";
      }
    }, "");
    return names;
  };

  render() {
    const { placement, prize, winners, boulpik } = this.props;
    const prizeMoney = currencyFormat(prize);
    if (boulpik) {
      switch (placement) {
        case "One":
          return (
            <div className={styles.container}>
              <Image src={"/1stPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_1stPlace")}</div>
                <div className={styles.text}>{this.renderNames(winners)}</div>
                <div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Two":
          return (
            <div className={styles.container}>
              <Image src={"/2ndPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_2ndPlace")}</div>
                <div className={styles.text}>{this.renderNames(winners)}</div>
                <div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Three":
          return (
            <div className={styles.container}>
              <Image src={"/3rdPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_3rdPlace")}</div>
                <div className={styles.text}>{this.renderNames(winners)}</div>
                <div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Four":
          return (
            <div className={styles.container}>
              <Image src={"/4thPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_4thPlace")}</div>
                <div className={styles.text}>{this.renderNames(winners)}</div>
                <div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Five":
          return (
            <div className={styles.container}>
              <Image src={"/5thPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_5thPlace")}</div>
                <div className={styles.text}>{this.renderNames(winners)}</div>
                <div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        default:
          return null;
      }
    } else {
      switch (placement) {
        case "One":
          return (
            <div className={styles.container}>
              <Image src={"/1stPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_1stPlace")}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Two":
          return (
            <div className={styles.container}>
              <Image src={"/2ndPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_2ndPlace")}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Three":
          return (
            <div className={styles.container}>
              <Image src={"/3rdPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_3rdPlace")}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Four":
          return (
            <div className={styles.container}>
              <Image src={"/4thPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_4thPlace")}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        case "Five":
          return (
            <div className={styles.container}>
              <Image src={"/5thPlace.png"} className={styles.image} />
              <div className={styles.rankContainer}>
                <div className={styles.text}>{getLabel("_5thPlace")}</div>
              </div>
              <div className={styles.arrowContainer}>
                <Icon name={"arrow right"} className={styles.arrow} />
              </div>
              <div className={styles.prizeContainer}>
                <div className={styles.text}>{prizeMoney}</div>
              </div>
            </div>
          );
        default:
          return null;
      }
    }
  }
}

export default Prizes;
