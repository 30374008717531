import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import Draws from "../../components/Draws/Draws";
import { getDraws } from "../../redux/actions/draw";
import { createBoulpik } from "../../redux/actions/boulpik";
import { endNavigate, navigate } from "../../redux/actions/navigation";

class ChooseDrawScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		await this.props.dispatch(getDraws());
	};

	handleDrawPress = async (end, price) => {
		await this.props.dispatch(createBoulpik(end, price));
		await this.props.dispatch(navigate("/my-boulpik/choose-draw/boulpik"));
	};

	render() {
		const { draw, navigation, location } = this.props;

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}

		return (
			<Container title={getLabel("ChooseDraw")} nested>
				<Draws data={draw?.draws} 
				onPress={this.handleDrawPress} />
			</Container>
		);
	}
}

function mapStateToProps({ draw, navigation }) {
	console.log("draw : ",draw?.draws);
	return { draw, navigation };
}

export default withRouter(connect(mapStateToProps)(ChooseDrawScreen));
