import React, { PureComponent } from "react";
import { getLabel } from "../../constants/Strings";
import styles from "./BoulcashDetailItem.module.css";
import { Accordion, Icon } from "semantic-ui-react";

class BoulcashDetailItem extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
		  value: 0,
		  expanded: true,
		};
	}

	render() {
		const { tirageDate, totalPlayed, username, tel, nickname,ville } = this.props;
		const { expanded, value } = this.state;
		let index = 0;
		return (
			
				<div className={styles.topSide}>
					
					<Accordion key={index} className={styles.containerAccordon}>
						<Accordion.Title
						active={expanded && value === index}
						onClick={() => this.handlePress(index)}
						className={expanded && value === index ? styles.expandedTitleText : styles.titleText}>
						<div className={styles.title}>{getLabel("Draw") + ": " + tirageDate}</div>
						
						</Accordion.Title>
						<Accordion.Content active={expanded} className={styles.contentContainer}>
							<div className={styles.topContainer}>
								<div className={styles.insideContainer}>
									<div className={styles.titleText}>
										<Icon name={"user"} className={styles.titleIcon} />
										{username}
									</div>
									<div className={styles.titleText}>{getLabel("Total") + " : " + totalPlayed}</div>
								</div>

								<div className={styles.insideContainer}>
									<div className={styles.titleText}>
										<Icon name={"map"} className={styles.titleIcon} />
										{ville}
									</div>

									<div className={styles.titleText}>{getLabel("Nickname") + " : " + nickname}</div>

								</div>

							</div>

							<div className={styles.centerContainer}>
								<div className={styles.titleText}>
									<Icon name={"phone"} className={styles.titleIcon} />
									{tel}
								</div>

							</div>
							
						</Accordion.Content>
					</Accordion>
					
					
				</div>
				
		
		);
	}
}

export default BoulcashDetailItem;
