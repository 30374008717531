import { GET_TRANSACTIONS, NEW_TRANSACTION } from "../actions/types";

export default function transactions(state = { transactions: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_TRANSACTIONS:
			return { ...state, transactions: payload.data };

		case NEW_TRANSACTION:
			return { ...state, transactions: [...state.transactions, payload.data] };

		default:
			return state;
	}
}
