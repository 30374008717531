import {
	LOGIN,
	LOGGED_USER,
	REGISTER_CLIENT,
	LOGOUT,
	REFRESH_CREDIT,
	EDIT_USER,
	VERIFY_USER,
	VERIFY_PIN,
	RESET_PASSWORD,
	DISMISS_MODAL,
	NEW_TRANSACTION,
	TOTAL_JOUEUR
} from "../actions/types";

export default function user(
	state = {
		_id: undefined,
		credit: 0,
		nom: "",
		role: "",
		tel: "",
		email: "",
		surnom: "",
		percent: "",
		address: "",
		token: null,
		pinModal: false,
		passwordModal: false,
		totalJoueur : 0
	},
	action
) {
	const { type, payload } = action;
	switch (type) {
		case LOGIN:
			return {
				...state,
				_id: payload.user._id,
				credit: payload.user.credit,
				nom: payload.user.nom,
				surnom: payload.user.surnom,
				role: payload.user.role,
				tel: payload.user.tel,
				percent: payload.user.percent,
				email: payload.user.email,
				address: payload.user.address,
				token: payload.token,
			};

		case LOGGED_USER:
			return {
				...state,
				_id: payload.user._id,
				credit: payload.user.credit,
				nom: payload.user.nom,
				surnom: payload.user.surnom,
				role: payload.user.role,
				tel: payload.user.tel,
				percent: payload.user.percent,
				email: payload.user.email,
				address: payload.user.address,
				token: payload.token,
			};

		case REGISTER_CLIENT:
			return {
				...state,
				_id: payload.user._id,
				credit: payload.user.credit,
				nom: payload.user.nom,
				surnom: payload.user.surnom,
				role: payload.user.role,
				tel: payload.user.tel,
				percent: payload.user.percent,
				email: payload.user.email,
				address: payload.user.address,
				token: payload.token,
			};

		case LOGOUT:
			return {
				...state,
				_id: null,
				credit: 0,
				nom: "",
				role: "",
				tel: "",
				email: "",
				surnom: "",
				addresse: "",
				token: null,
			};

		case REFRESH_CREDIT:
			return {
				...state,
				credit: payload.data,
			};
		
		case TOTAL_JOUEUR:
			return {
				...state,
				totalJoueur: payload.data,
			};
	

		case NEW_TRANSACTION:
			return {
				...state,
				credit: state.credit + payload.data.balance,
			};

		case EDIT_USER:
			return {
				...state,
				_id: payload.data._id,
				credit: payload.data.credit,
				nom: payload.data.nom,
				role: payload.data.role,
				tel: payload.data.tel,
				email: payload.data.email,
				surnom: payload.data.surnom,
				addresse: payload.data.addresse,
			};

		case VERIFY_USER:
			return { ...state, pinModal: true, passwordModal: false };

		case VERIFY_PIN:
			return { ...state, pinModal: false, passwordModal: true };

		case RESET_PASSWORD:
			return { ...state, pinModal: false, passwordModal: false };

		case DISMISS_MODAL:
			return { ...state, pinModal: false, passwordModal: false };

		default:
			return state;
	}
}
