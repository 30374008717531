import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import JavascriptTimeAgo from "javascript-time-ago";
import * as serviceWorker from "./serviceWorker";

// The desired locales.
import en from "javascript-time-ago/locale/en";

//Styles
import "./assets/fonts/RobotoCondensed-Bold.ttf";
import "./assets/fonts/RobotoCondensed-BoldItalic.ttf";
import "./assets/fonts/RobotoCondensed-Italic.ttf";
import "./assets/fonts/RobotoCondensed-Light.ttf";
import "./assets/fonts/RobotoCondensed-LightItalic.ttf";
import "./assets/fonts/RobotoCondensed-Regular.ttf";
import "semantic-ui-css/semantic.min.css";
import "./index.css";

JavascriptTimeAgo.locale(en);

ReactDOM.render(
	<Provider store={store}>
		<HashRouter>
			<App />
		</HashRouter>
	</Provider>,
	document.getElementById("root")
);

serviceWorker.register();
