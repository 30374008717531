import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, GET_HISTORY } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";

function requestSuccess(type, data) {
  return { type, payload: { data } };
}

function requestFail(errorCode, language) {
  return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getHistory() {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.get("/getFiveHistoryTirage")
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_HISTORY, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}
