import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import { getDraws } from "../../redux/actions/draw";
import { endNavigate } from "../../redux/actions/navigation";

import Container from "../../components/Container/Container";
import Calendar from "../../components/Calendar/Calendar";

class NextDrawScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		await this.props.dispatch(getDraws());
	};

	processDraws = draw => {
		let dates = [];
		if (draw !== undefined) {
			draw.forEach(item => {
				const dateString = item.end.split("/");
				const dd = dateString[0];
				const mm = dateString[1] - 1;
				const yyyy = dateString[2];
				const newDate = new Date(yyyy, mm, dd);
				dates.push(newDate);
			});
			return dates;
		}
		return null;
	};

	render() {
		const { draw } = this.props;
		return (
			<Container title={getLabel("NextDraws")}>
				<Calendar markedDates={this.processDraws(draw.draws)} value={""} onChange={() => {}} />
			</Container>
		);
	}
}

function mapStateToProps({ draw, navigation }) {
	return { draw, navigation };
}

export default connect(mapStateToProps)(NextDrawScreen);
