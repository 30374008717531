import React, { Component } from "react";
import { Grid, Paper, Tooltip, IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
// import ComponentToPrint from "./ComponentToPrint";
import ComponentToPrint from "./ComponenToPrint";

class FinalPrinter extends Component {
	render() {
		return (
			<Paper style={{ padding: 10 }}>
				<Grid container direction="column">
					<Grid item xs={12}>
						<Grid container justify="flex-end" alignItems="flex-end">
							<Grid item>
								<ReactToPrint
									trigger={() => (
										<Tooltip title="Print">
											<IconButton>
												<PrintIcon />
											</IconButton>
										</Tooltip>
									)}
									content={() => this.componentRef}
									//documentTitle="Delivery_Sheet"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<ComponentToPrint item={this.props.item} ref={(el) => (this.componentRef = el)} />
					</Grid>
				</Grid>
			</Paper>
		);
	}
}

export default FinalPrinter;
