import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { endNavigate } from "../../redux/actions/navigation";
import Container from "../../components/Container/Container";
import Input from "../../components/Input/Input";
import { transferCredit } from "../../redux/actions/user";
import { currencyFormat } from "../../constants/Utils";
import styles from "./TransferCreditScreen.module.css";

class TransferCreditScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      user: "",
      credit: "",
      confirmModal: false,
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  handleTransferCredit = async () => {
    const { user, credit } = this.state;
    await this.props.dispatch(transferCredit(user, credit));
  };

  openModal = () => {
    this.setState({ confirmModal: true });
  };

  closeModal = () => {
    this.setState({ confirmModal: false });
  };

  onInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { user } = this.props;
    const { confirmModal } = this.state;
    const PhoneTitle = user.role === "User" || "Detaillants" ? "Phone" : "EmailOrPhone";
    return (
      <Container title={getLabel("TransferCredit")}>
        <Modal open={confirmModal} onClose={this.closeModal}>
          <Modal.Content className={styles.content}>
            <p className={styles.text}>{getLabel("ConfirmTransfer")}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal} color={"red"}>
              {getLabel("Cancel")}
            </Button>
            <Button onClick={this.handleTransferCredit} color={"red"}>
              {getLabel("Done")}
            </Button>
          </Modal.Actions>
        </Modal>
        <div className={styles.balanceContainer}>
          <div className={styles.titleText}>{getLabel("Balance") + ": " + currencyFormat(user.credit)}</div>
        </div>
        <div className={styles.inputsContainer}>
          <Input
            icon={"user"}
            name={"user"}
            value={this.state.user}
            placeholder={getLabel(PhoneTitle)}
            onChange={this.onInputChange}
          />
          <Input
            icon={"credit card"}
            name={"credit"}
            type={"number"}
            value={this.state.credit}
            placeholder={getLabel("CreditToTransfer")}
            onChange={this.onInputChange}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={this.handleTransferCredit}
            disabled={this.state.credit === "" || this.state.user === ""}>
            <Icon name={"send"} />
            <div className={styles.text}>{getLabel("TransferCredit")}</div>
          </Button>
        </div>
      </Container>
    );
  }
}

function mapStateToProps({ user, navigation }) {
  return { user, navigation };
}

export default connect(mapStateToProps)(TransferCreditScreen);
