import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, GET_TRANSACTIONS, NEW_TRANSACTION } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";

function requestSuccess(type, data) {
  return { type, payload: { data } };
}

function requestFail(errorCode, language) {
  return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getTransactions() {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.get("/myTransactions")
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_TRANSACTIONS, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function startMoncash(amount, windowRef) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/monCash", {
      amount,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(apiEnd());
          windowRef.location = response.data.data;
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function newTransaction(transaction) {
  return (dispatch) => {
    dispatch(requestSuccess(NEW_TRANSACTION, transaction));
  };
}
