import { CHANGE_LANGUAGE, REHYDRATE_SETTINGS } from "./types";
import Cookie from "react-cookies";

function request(type, data) {
	return { type, payload: { data } };
}

export function importSettings() {
	return async dispatch => {
		const settings = await Cookie.load("settings");
		if (settings !== undefined) {
			dispatch(request(REHYDRATE_SETTINGS, settings));
		}
	};
}

export function changeLanguage(language) {
	return dispatch => {
		Cookie.save("settings", language, { path: "/" });
		dispatch(request(CHANGE_LANGUAGE, language));
	};
}
