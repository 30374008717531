import { GET_CART, ADD_BOULPIK_TO_CART, DELETE_CART, BUY_ALL_FROM_CART, BUY_ONE_FROM_CART } from "../actions/types";
import { filter, negate } from "lodash";

export default function cart(state = { items: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CART:
			return { ...state, items: payload.data };

		case ADD_BOULPIK_TO_CART:
			return { ...state, items: state.items.concat(payload.data) };

		case DELETE_CART:
			return { ...state, items: payload.data };

		case BUY_ALL_FROM_CART:
			return { ...state, items: [] };

		case BUY_ONE_FROM_CART:
			const newItems = filter(
				state.items,
				negate(function(item) {
					return item.fecha === payload.data.fecha && item.boulpik === payload.data.boulpik;
				})
			);
			return {
				...state,
				items: newItems
			};

		default:
			return state;
	}
}
