import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Accordion, Icon } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import { endNavigate } from "../../redux/actions/navigation";
//import { isEmpty } from "lodash";

import styles from "./HelpScreen.module.css";

class HelpScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      contactModal: false,
      value: 0,
      expanded: true,
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  handlePress = (id) => {
    if (this.state.value === id) {
      return this.setState({ value: id, expanded: !this.state.expanded });
    }
    return this.setState({ value: id, expanded: true });
  };

  handleContactUs = () => {
    this.setState({ contactModal: true });
  };

  closeModal = () => {
    this.setState({ contactModal: false });
  };

  render() {
    const { value, expanded } = this.state;
    return (
      <Container title={getLabel("Help")}>
        <div className={styles.text}>{getLabel("FAQ")}</div>
        {/**Q1 */}
        <Accordion className={styles.container}>
          <Accordion.Title
            active={expanded && value === 1}
            onClick={() => this.handlePress(1)}
            className={styles.titleText}>
            <div className={styles.title}>{getLabel("Q1")}</div>
            <Icon name={expanded && value === 1 ? "chevron up" : "chevron down"} className={styles.titleIcon} />
          </Accordion.Title>
          <Accordion.Content active={expanded && value === 1} className={styles.contentContainer}>
            <p className={styles.answerText}>{getLabel("A1")}</p>
          </Accordion.Content>
        </Accordion>
        {/**Q2 */}
        <Accordion className={styles.container}>
          <Accordion.Title
            active={expanded && value === 2}
            onClick={() => this.handlePress(2)}
            className={styles.titleText}>
            <div className={styles.title}>{getLabel("Q2")}</div>
            <Icon name={expanded && value === 2 ? "chevron up" : "chevron down"} className={styles.titleIcon} />
          </Accordion.Title>
          <Accordion.Content active={expanded && value === 2} className={styles.contentContainer}>
            <p className={styles.answerText}>{getLabel("A2")}</p>
          </Accordion.Content>
        </Accordion>
        {/**Q3 */}
        <Accordion className={styles.container}>
          <Accordion.Title
            active={expanded && value === 3}
            onClick={() => this.handlePress(3)}
            className={styles.titleText}>
            <div className={styles.title}>{getLabel("Q3")}</div>
            <Icon name={expanded && value === 3 ? "chevron up" : "chevron down"} className={styles.titleIcon} />
          </Accordion.Title>
          <Accordion.Content active={expanded && value === 3} className={styles.contentContainer}>
            <p className={styles.answerText}>{getLabel("A3")}</p>
          </Accordion.Content>
        </Accordion>
        {/**Q4 */}
        <Accordion className={styles.container}>
          <Accordion.Title
            active={expanded && value === 4}
            onClick={() => this.handlePress(4)}
            className={styles.titleText}>
            <div className={styles.title}>{getLabel("Q4")}</div>
            <Icon name={expanded && value === 4 ? "chevron up" : "chevron down"} className={styles.titleIcon} />
          </Accordion.Title>
          <Accordion.Content active={expanded && value === 4} className={styles.contentContainer}>
            <p className={styles.answerText}>{getLabel("A4")}</p>
          </Accordion.Content>
        </Accordion>
        {/**Q5 */}
        <Accordion className={styles.container}>
          <Accordion.Title
            active={expanded && value === 5}
            onClick={() => this.handlePress(5)}
            className={styles.titleText}>
            <div className={styles.title}>{getLabel("Q5")}</div>
            <Icon name={expanded && value === 5 ? "chevron up" : "chevron down"} className={styles.titleIcon} />
          </Accordion.Title>
          <Accordion.Content active={expanded && value === 5} className={styles.contentContainer}>
            <p className={styles.answerText}>{getLabel("A5")}</p>
          </Accordion.Content>
        </Accordion>
        {/**Q6 */}
        <Accordion className={styles.container}>
          <Accordion.Title
            active={expanded && value === 6}
            onClick={() => this.handlePress(6)}
            className={styles.titleText}>
            <div className={styles.title}>{getLabel("Q6")}</div>
            <Icon name={expanded && value === 6 ? "chevron up" : "chevron down"} className={styles.titleIcon} />
          </Accordion.Title>
          <Accordion.Content active={expanded && value === 6} className={styles.contentContainer}>
            <p className={styles.answerText}>{getLabel("A6")}</p>
          </Accordion.Content>
        </Accordion>
        {/**Q7 */}
        <Accordion className={styles.container}>
          <Accordion.Title
            active={expanded && value === 7}
            onClick={() => this.handlePress(7)}
            className={styles.titleText}>
            <div className={styles.title}>{getLabel("Q7")}</div>
            <Icon name={expanded && value === 7 ? "chevron up" : "chevron down"} className={styles.titleIcon} />
          </Accordion.Title>
          <Accordion.Content active={expanded && value === 7} className={styles.contentContainer}>
            <p className={styles.answerText}>{getLabel("A7")}</p>
          </Accordion.Content>
        </Accordion>
        <Button
          color={"red"}
          content={getLabel("ContactUs")}
          className={styles.button}
          onClick={this.handleContactUs}
        />
      </Container>
    );
  }
}

function mapStateToProps({ navigation }) {
  return { navigation };
}

export default connect(mapStateToProps)(HelpScreen);
