import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";
import styles from "./DealerItem.module.css";

class DealerItem extends PureComponent {
	render() {
		const { name, address, city, zone, phone, email, onPress } = this.props;
		return (
			<div className={styles.container} onClick={onPress}>
				<div className={styles.topSide}>
					<div className={styles.item}>
						<Icon className={styles.icon} name={"user"} />
						<div className={styles.text}>{name}</div>
					</div>
					<div className={styles.item}>
						<Icon className={styles.icon} name={"map marker alternate"} />
						<div className={styles.text}>{city}</div>
					</div>
				</div>
				<div className={styles.topSide}>
					<div className={styles.item}>
						<Icon className={styles.icon} name={"phone"} />
						<div className={styles.text}>{phone}</div>
					</div>
					<div className={styles.item}>
						<Icon className={styles.icon} name={"map pin"} />
						<div className={styles.text}>{zone}</div>
					</div>
				</div>
				<div className={styles.item} style={{ display: email ? "flex" : "none" }}>
					<Icon className={styles.icon} name={"mail"} />
					<div className={styles.text}>{email}</div>
				</div>
				<div className={styles.item}>
					<Icon className={styles.icon} name={"map"} />
					<div className={styles.text}>{address}</div>
				</div>
			</div>
		);
	}
}

export default DealerItem;
