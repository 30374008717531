import { GET_HISTORY } from "../actions/types";

export default function history(state = { history: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_HISTORY:
			const sortedArray = payload.data;
			return { ...state, history: sortedArray };

		default:
			return state;
	}
}
