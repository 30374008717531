import React, { PureComponent } from "react";
import { splitNumbers } from "../../constants/Utils";
import styles from "./Boulpik.module.css";

class Boulpik extends PureComponent {
	renderBoulpiks = (numbers) => {
		const items = splitNumbers(numbers);
		const boulpiks = items.map((item, index) => {
			return (
				<div className={styles.boulpiks} key={index}>
					<div className={styles.text}>{item}</div>
				</div>
			);
		});
		return boulpiks;
	};

	render() {
		const { boulpik, counter } = this.props;
		if (counter) {
			return (
				<div className={styles.container}>
					<div className={styles.titleText}>Boulcash:({counter})</div>
					<div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
				</div>
			);
		}
		return (
			<div className={styles.container}>
				<div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
			</div>
		);
	}
}

export default Boulpik;
