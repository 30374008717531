import { API_FAILURE, DISMISS_ERROR, API_ERROR } from "../actions/types";
import { getErrorMessage } from "../../constants/Error";

export default function error(state = { error: false, errorCode: "", errorTitle: "", errorText: "" }, action) {
	const { type, payload } = action;
	let errorConstants = {};
	switch (type) {
		case API_FAILURE:
			errorConstants = getErrorMessage(payload.data, payload.language);
			return {
				...state,
				error: true,
				errorCode: payload.data,
				errorTitle: errorConstants.title,
				errorText: errorConstants.text
			};

		case API_ERROR:
			errorConstants = getErrorMessage("0000", payload.language);
			return {
				...state,
				error: true,
				errorCode: "0000",
				errorTitle: errorConstants.title,
				errorText: errorConstants.text
			};

		case DISMISS_ERROR:
			return { ...state, error: false, errorCode: "", errorTitle: "", errorText: "" };

		default:
			return state;
	}
}
