import React, { PureComponent } from "react";
import { Route, Redirect } from "react-router-dom";
import { omit } from "lodash";

class PublicRoute extends PureComponent {
	getPageForRole = (isLoggedIn, role) => {
		let page = "";
		if (isLoggedIn && role !== "") {
			switch (role) {
				case "User":
					page = "/weekly-prizes";
					break;
				case "Investor":
					page = "/benefit";
					break;
				case "Super":
					page = "/weekly-prizes";
					break;
				case "Admin":
					page = "/weekly-prizes";
					break;
				case "Detaillants":
					page = "/transfer-credit";
					break;
				case "Distributeurs":
					page = "/transfer-credit";
					break;
				default:
					break;
			}
		}
		return page;
	};

	render() {
		const { isLoggedIn, role } = this.props;
		const oldProps = omit(this.props, "isLoggedIn");
		const page = this.getPageForRole(isLoggedIn, role);
		return isLoggedIn ? <Redirect to={page} /> : <Route {...oldProps} />;
	}
}

export default PublicRoute;
