import { currencyFormat } from "./Utils";

export function generateMessage(code, language, data) {
	switch (code) {
		case "6000":
			switch (language) {
				case "english":
					return `You have transfered ${currencyFormat(data.ammount)} to ${data.person}.`;
				case "french":
					return `Vous avez transferé ${currencyFormat(data.ammount)} a ${data.person}.`;
				case "creole":
					return `Ou voye ${currencyFormat(data.ammount)} pou ${data.person}.`;
				default:
					return null;
			}

		case "6001":
			switch (language) {
				case "english":
					return `The Draw ${data.draw} has ended.`;
				case "french":
					return `Le Tirage ${data.draw} est terminé.`;
				case "creole":
					return `Tiraj ${data.draw} fini.`;
				default:
					return null;
			}

		case "6002":
			switch (language) {
				case "english":
					return `The Draw ${data.draw} is available, Go Play!`;
				case "french":
					return `Le Tirage ${data.draw} est disponible, Aller Jouer!`;
				case "creole":
					return `Tiraj ${data.draw} disponib ,Ale Jwe!`;
				default:
					return null;
			}

		case "6003":
			switch (language) {
				case "english":
					return `The Draw ${data.draw} has been extended to ${data.newDate}.`;
				case "french":
					return `Le Tirage ${data.draw} est prolongé pour ${data.newDate}.`;
				case "creole":
					return `Tiraj ${data.draw} prolonje pou ${data.newDate}.`;
				default:
					return null;
			}

		case "6004":
			switch (language) {
				case "english":
					return `Boulcash ${data.boulpik} has been added to your Cart.`;
				case "french":
					return `Boulcash ${data.boulpik} a été ajouté a votre Charriot.`;
				case "creole":
					return `Boulcash ${data.boulpik} ajoute nan Charyo ou.`;
				default:
					return null;
			}

		case "6005":
			switch (language) {
				case "english":
					return `You have bought the boulcash ${data.boulpik}.`;
				case "french":
					return `Vous avez acheté le Boulcash ${data.boulpik}.`;
				case "creole":
					return `Ou achte yon Boulcash ${data.boulpik}.`;
				default:
					return null;
			}

		case "6006":
			switch (language) {
				case "english":
					return `Someone else bought the boulcash ${data.boulpik}.`;
				case "french":
					return `Quelqu'un d'autre a acheté votre Boulcash ${data.boulpik}.`;
				case "creole":
					return `Gen yon lot moun ki achte Boulcash ou a ${data.boulpik}.`;
				default:
					return null;
			}

		case "6007":
			switch (language) {
				case "english":
					return `You have won ${currencyFormat(data.ammount)} from the Draw ${data.draw}.`;
				case "french":
					return `Vous avez gagné ${currencyFormat(data.ammount)} dans le Tirage ${data.draw}.`;
				case "creole":
					return `Ou genyen ${currencyFormat(data.ammount)} nan tiraj ${data.draw}.`;
				default:
					return null;
			}

		case "6009":
			switch (language) {
				case "english":
					return `You added ${currencyFormat(data.ammount)} to your account.`;
				case "french":
					return `Vous avez ajouté ${currencyFormat(data.ammount)} a votre compte.`;
				case "creole":
					return `Ou ajoute ${currencyFormat(data.ammount)} sou kont ou.`;
				default:
					return null;
			}

		case "6010":
			switch (language) {
				case "english":
					return `You withdrawed ${currencyFormat(data.ammount)} from your account.`;
				case "french":
					return `Vous avez retrait ${currencyFormat(data.ammount)} a votre compte.`;
				case "creole":
					return `Ou retire ${currencyFormat(data.ammount)} sou kont ou.`;
				default:
					return null;
			}

		case "6011":
			switch (language) {
				case "english":
					return `${data.person} has transfered ${currencyFormat(data.ammount)} to your account.`;
				case "french":
					return `${data.person} a transferé ${currencyFormat(data.ammount)} a votre compte.`;
				case "creole":
					return `${data.person} voye ${currencyFormat(data.ammount)} sou kont ou.`;
				default:
					return null;
			}

		case "6012":
			switch (language) {
				case "english":
					return `You don't have enough funds to buy this Boulcash`;
				case "french":
					return `Vous n'avez pas assez de fonds pour acheter ce Boulcash.`;
				case "creole":
					return `Ou pa gen ase lajan pou achte Boulcash sa.`;
				default:
					return null;
			}
			case "6013":
				switch (language) {
					case "english":
						return `${data.repetition} Another person has this Boulcash ${data.boulpik}.`;
					case "french":
						return ` ${data.repetition}Quelqu'un d'autre a ce Boulcash ${data.boulpik}.`;
					case "creole":
						return ` ${data.repetition}Gen yon lot moun ki gen Boulcash ou a ${data.boulpik}.`;
					default:
						return null;
				}

		default:
			return null;
	}
}
