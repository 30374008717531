const Messages = {
	values: {
		"0501": {
			english: {
				text: "Thank you, your boulcash has been bought successfully.",
				title: "Success!"
			},
			french: {
				text: "Merci, votre boulcash a été acheté avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Mèsi, boulcash ou a te achte avèk siksè.",
				title: "Siksè!"
			}
		},
		"0502": {
			english: {
				text: "Thank you, your boulcash has been bought successfully.\nWarning: 1 more person bought this boulcash.",
				title: "Success!"
			},
			french: {
				text: "Merci, votre boulcash a été acheté avec succès.Attention: quelqu'un d'autres a deja acheté ce boulcash.",
				title: "Succès!"
			},
			creole: {
				text: "Mèsi, boulcash ou a te achte avèk siksè.\nAvètisman: gen yn moun deja ki achte boulcash la.",
				title: "Siksè!"
			}
		},
		"0503": {
			english: {
				text: "Thank you, your boulcash has been bought successfully.\nWarning: 2 more people bought this boulcash.",
				title: "Success!"
			},
			french: {
				text:
					"Merci, votre boulcash a été acheté avec succès.Attention: il ya deux personnes qui ont deja acheté ce boulcash.",
				title: "Succès!"
			},
			creole: {
				text: "Mèsi, boulcash ou a te achte avèk siksè.\nAvètisman: gen 2 moun deja ki t achte boulcash sa.",
				title: "Siksè!"
			}
		},
		Boulpik_Cart: {
			english: {
				text: "Thank you, your boulcash has been added to cart successfully.",
				title: "Success!"
			},
			french: {
				text: "Merci, votre boulcash a bien été ajouté au panier.",
				title: "Succès!"
			},
			creole: {
				text: "Mèsi poutèt ou, ou te ajoute boulcash ou nan kabwa avèk siksè.",
				title: "Siksè!"
			}
		},
		Boulpik_Buy_Multiple: {
			english: {
				text: "Thank you, all your boulpiks have been bought successfully.",
				title: "Success!"
			},
			french: {
				text: "Merci, tous vos boulpiks ont été achetés avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Mèsi, tout boulcash ou yo te achte avèk siksè.",
				title: "Siksè!"
			}
		},
		Transfer_Credit: {
			english: {
				text: "The credit has been transferred successfully.",
				title: "Success!"
			},
			french: {
				text: "Le crédit a été transféré avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Kredi a te transfere avèk siksè.",
				title: "Siksè!"
			}
		},
		Create_Account: {
			english: {
				text: "Account created successfully.",
				title: "Success!"
			},
			french: {
				text: "Compte créé avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Kont kreye avèk siksè.",
				title: "Siksè!"
			}
		},
		Edit_Account: {
			english: {
				text: "Account edited successfully.",
				title: "Success!"
			},
			french: {
				text: "Compte édité avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Kont modifye avèk siksè.",
				title: "Siksè!"
			}
		},
		Create_Draw: {
			english: {
				text: "Draw created successfully.",
				title: "Success!"
			},
			french: {
				text: "Tirage créé avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Tiraj kreye avèk siksè.",
				title: "Siksè!"
			}
		},
		Update_Draw: {
			english: {
				text: "Draw has been updated successfully.",
				title: "Success!"
			},
			french: {
				text: "Tirage a été mise a jour avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Tiraj modifye avèk siksè.",
				title: "Siksè!"
			}
		},

		

		Email_Sent: {
			english: {
				text: "Email has been sent successfully.",
				title: "Success!"
			},
			french: {
				text: "Email envoyé avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "imel ou a ale avèk siksè.",
				title: "Siksè!"
			}
		},

		Reset_Password: {
			english: {
				text: "Password changed successfully.",
				title: "Success!"
			},
			french: {
				text: "Le mot de passe a été changé avec succès.",
				title: "Succès!"
			},
			creole: {
				text: "Modpas chanje avèk siksè.",
				title: "Siksè!"
			}
		}
	}
};

export function getSuccessMessage(code, language) {
	const Code = Messages.values[code];
	return Code[language];
}
