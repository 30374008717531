import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { endNavigate } from "../../redux/actions/navigation";
import { createSeller } from "../../redux/actions/user";
import Container from "../../components/Container/Container";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import Input from "../../components/Input/Input";
import styles from "./CreateSellerScreen.module.css";

class CreateSellerScreen extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			email: "",
			phone: "",
			city: "",
			password: ""
		};

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	handleSignUp = async () => {
		const { name, email, phone, city, password } = this.state;
		await this.props.dispatch(createSeller(name, email, phone, city, password));
		this.setState({
			name: "",
			email: "",
			phone: "",
			city: "",
			password: ""
		});
	};

	onInputChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	render() {
		const { name, phone, city, password, email } = this.state;
		return (
			<Container title={getLabel("CreateSeller")}>
				<div className={styles.inputsContainer}>
					<Input
						icon={"user"}
						name={"name"}
						value={name}
						placeholder={getLabel("FullName")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"mail"}
						name={"email"}
						value={email}
						placeholder={getLabel("Email")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"phone"}
						name={"phone"}
						type={"number"}
						value={phone}
						placeholder={getLabel("Phone")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"marker"}
						name={"city"}
						value={city}
						placeholder={getLabel("City")}
						onChange={this.onInputChange}
					/>
					<PasswordInput
						icon={"lock"}
						name={"password"}
						value={password}
						placeholder={getLabel("Password")}
						onChange={this.onInputChange}
					/>
				</div>
				<div className={styles.buttonsContainer}>
					<Button className={styles.button} onClick={this.handleSignUp}>
						<Icon name={"user plus"} />
						<div className={styles.text}>{getLabel("CreateSeller")}</div>
					</Button>
				</div>
			</Container>
		);
	}
}

function mapStateToProps({ user, navigation }) {
	return { user, navigation };
}

export default connect(mapStateToProps)(CreateSellerScreen);
