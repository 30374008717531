import { GET_INVERSTORS } from "../actions/types";

export default function draw(state = { investors: [] }, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INVERSTORS:
      return { ...state, investors: payload.data };
    default:
      return state;
  }
}
