import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import Draws from "../../components/Draws/Draws";

import { getListInvestors } from "../../redux/actions/investor";

import {getDetailsDraws} from "../../redux/actions/draw";

import { endNavigate } from "../../redux/actions/navigation";
import { isEmpty } from "lodash";


class SeeInvestorScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	
	}

	componentDidMount = async () => {
		await this.props.dispatch(getListInvestors());
		await this.props.dispatch(getDetailsDraws());
	};

	renderDraws = (data) => {
		if (!isEmpty(data)) {
			return <Draws data={data} type="prizes" />;
		}
		return null;
	};

	completeInvestorData = (data,benefit) => {
		if (!isEmpty(data)) {
			data.forEach(obj => {
				obj["benefit"] = benefit;
			});;

			if(data && data.length>0){
				this.setState({dataInvestor:data});
			}
			return data;
		}
		return null;
	};

	renderDrawList = (data) => {
		if (!isEmpty(data)) {
			return <Draws data={data} type="investors" />;
		}
		return null;
	};

	render() {
		const { investor, user } = this.props;
		

		if (user.role !== "Investor") {
			return <Container title={getLabel("Investor")}>{this.renderDrawList(investor.investors ?? [])}</Container>;
		}
	}
}

function mapStateToProps({ user, history, navigation , investor, draw}) {
	console.log(investor.investors);
	return { history, user, navigation, investor, draw };
}

export default connect(mapStateToProps)(SeeInvestorScreen);
