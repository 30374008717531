import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
//import Draws from "../../components/Draws/Draws";
//import { getHistory } from "../../redux/actions/history";
import { endNavigate } from "../../redux/actions/navigation";
//import { isEmpty } from "lodash";

class SalesScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		//await this.props.dispatch(getHistory());
	};

	// renderDraws = data => {
	// 	if (!isEmpty(data)) {
	// 		return <Draws data={data} type="sales" />;
	// 	}
	// 	return null;
	// };

	render() {
		return <Container title={getLabel("Sales")}></Container>;
	}
}

function mapStateToProps({ history, navigation }) {
	return { history, navigation };
}

export default connect(mapStateToProps)(SalesScreen);
