import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import { Button, Divider, Icon, Modal } from "semantic-ui-react";
import Container from "../../components/Container/Container";
import { buyBoulpik, addBoulpikToCart, generateBoulpik } from "../../redux/actions/boulpik";
import { endNavigate } from "../../redux/actions/navigation";
import { currencyFormat } from "../../constants/Utils";
import BoulpikInput from "../../components/BoulpikInput/BoulpikInput";
import styles from "./CreateBoulpikScreen.module.css";
import stylesCartes from "../CartScreen/CartScreen.module.css";

import FinalPrinter from "../Printer/FinalPrinter";

class CreateBoulpikScreen extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			boulpik: "",
			modalVisible: false,
			visiblePrinter: false,
			item: {},
		};

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.boulpik.boulpik !== prevProps.boulpik.boulpik) {
			this.setState({ boulpik: this.props.boulpik.boulpik });
		}
	};
	handlePrint = () => {
		const { date, boulpik, price } = this.props.boulpik;
		const { nom, _id } = this.props.user;
		const item = Object.assign(
			{},
			{
				nom: nom,
				userId: _id,

				today: Date.now(),
				username: "sondley",
				boulpik,
				date,
				price,
			}
		);

		this.setState({ modalVisible: false });
		this.setState({ visiblePrinter: true, item: item });

		//console.log("hereee");
		//return <PrinterComponent item={item} />;
	};

	handleCloseModal = () => {
		this.setState({ modalVisible: false });
	};

	closeModalPrinter = () => {
		this.setState({ visiblePrinter: false });
	};

	handlePrintModal = () => {
		window.print();

		// this.setState({ modalVisible: false });
	};

	handleAutoBoulpik = async (e) => {
		e.preventDefault();
		const { date, boulpik } = this.props.boulpik;
		await this.props.dispatch(generateBoulpik(date));
		this.setState({ boulpik });
	};

	handleBuyNow = async (e) => {
		e.preventDefault();
		this.setState({ modalVisible: true });
		//const { role } = this.props.user;
		//if (this.state.boulpik.length === 5) {
		//	if (role !== "Detaillants") {
		const { date, price } = this.props.boulpik;
		await this.props.dispatch(buyBoulpik(date, price, this.state.boulpik));
		//	}

		//this.setState({ boulpik: "" });
		//}
	};

	handleAddToCart = async (e) => {
		e.preventDefault();
		if (this.state.boulpik.length === 5) {
			const { date, price } = this.props.boulpik;
			await this.props.dispatch(addBoulpikToCart(date, price, this.state.boulpik));
			this.setState({ boulpik: "" });
		}
	};

	handleTextChange = (e) => {
		e.preventDefault();
		const { value } = e.target;
		if (!isNaN(value) && value.length <= 5) {
			return this.setState({ boulpik: value });
		}
	};

	handlePrinter = (e) => {
		e.preventDefault();
	};

	render() {
		const { boulpik } = this.props;
		const disabled = this.state.boulpik.length !== 5;
		const { role } = this.props.user;

		if (role === "Detaillants") {
			return (
				<Container title={getLabel("New") + " Boulcash"} nested>
					<Modal size="small" open={this.state.modalVisible} onClose={this.handleCloseModal}>
						<Modal.Header className={stylesCartes.modalHeader}>{getLabel("PrintHeader")}</Modal.Header>
						<Modal.Content>
							<p className={stylesCartes.text}>{getLabel("Print")}</p>
						</Modal.Content>
						<Modal.Actions className={stylesCartes.modalActions}>
							<Button
								className={stylesCartes.actionButton}
								negative
								icon="cancel"
								labelPosition="right"
								content={getLabel("Cancel")}
								onClick={this.handleCloseModal}
							/>
							<Button
								className={stylesCartes.actionButton}
								positive
								icon="checkmark"
								labelPosition="right"
								content={getLabel("Ok")}
								onClick={this.handlePrint}
							/>
						</Modal.Actions>
					</Modal>

					<Modal size="small" open={this.state.visiblePrinter} onClose={this.closeModalPrinter}>
						<Modal.Actions className={stylesCartes.modalActions}></Modal.Actions>
						{/* <PrinterComponent item={this.state.item} /> */}

						{/* <OrderComponent item={this.state.item} /> */}

						<FinalPrinter item={this.state.item} />
						<Button
							className={stylesCartes.actionButton}
							negative
							icon="cancel"
							labelPosition="right"
							content={getLabel("Cancel")}
							onClick={this.closeModalPrinter}
						/>
					</Modal>
					<div className={styles.container}>
						<div className={styles.titleText}>{getLabel("Draw")}</div>
						<div className={styles.inputsContainer}>
							<div>
								<div className={styles.boulpikInfoContainer}>
									<div className={styles.titleText}>{getLabel("Draw") + ": " + boulpik.date}</div>
									<div className={styles.titleText}>{getLabel("Price") + ": " + currencyFormat(boulpik.price)}</div>
								</div>
							</div>
							<BoulpikInput value={this.state.boulpik} onChange={this.handleTextChange} />
							<div className={styles.autoBoulpik}>
								<Button className={styles.button} onClick={this.handleAutoBoulpik}>
									<Icon name={"reddit alien"} />
									<div className={styles.text}>{getLabel("AutoBoulpik")}</div>
								</Button>
							</div>
						</div>
						<div className={styles.buttonsContainer}>
							<Button className={styles.button} onClick={this.handleBuyNow} disabled={disabled}>
								<Icon name={"credit card"} />
								<div className={styles.text}>{getLabel("BuyNow")}</div>
							</Button>

							<Divider horizontal className={styles.divider}>
								{getLabel("Or")}
							</Divider>
							<Button className={styles.button2} onClick={this.handleAddToCart} disabled={disabled}>
								<Icon name={"cart"} />
								<div className={styles.text}>{getLabel("AddToCart")}</div>
							</Button>
						</div>
					</div>
				</Container>
			);
		} else {
			return (
				<Container title={getLabel("New") + " Boulcash"} nested>
					<div className={styles.container}>
						{/* <div className={styles.titleText}>{getLabel("Draw")}</div> */}
						<div className={styles.inputsContainer}>
							<div>
								<div className={styles.boulpikInfoContainer}>
									<div className={styles.titleText}>{getLabel("Draw") + ": " + boulpik.date}</div>
									<div className={styles.titleText}>{getLabel("Price") + ": " + currencyFormat(boulpik.price)}</div>
								</div>
							</div>
							<BoulpikInput value={this.state.boulpik} onChange={this.handleTextChange} />
							<div className={styles.autoBoulpik}>
								<Button className={styles.button} onClick={this.handleAutoBoulpik}>
									<Icon name={"reddit alien"} />
									<div className={styles.text}>{getLabel("AutoBoulpik")}</div>
								</Button>
							</div>
						</div>
						<div className={styles.buttonsContainer}>
							<Button className={styles.button} onClick={this.handleBuyNow} disabled={disabled}>
								<Icon name={"credit card"} />
								<div className={styles.text}>{getLabel("BuyNow")}</div>
							</Button>
							<Divider horizontal className={styles.divider}>
								{getLabel("Or")}
							</Divider>
							<Button className={styles.button2} onClick={this.handleAddToCart} disabled={disabled}>
								<Icon name={"cart"} />
								<div className={styles.text}>{getLabel("AddToCart")}</div>
							</Button>
						</div>
					</div>
				</Container>
			);
		}
	}
}

function mapStateToProps({ boulpik, navigation, user }) {
	return { boulpik, navigation, user };
}

export default connect(mapStateToProps)(CreateBoulpikScreen);
