import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Icon, Image } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { endNavigate, navigate } from "../../redux/actions/navigation";

import styles from "./TransitionScreen.module.css";

class TransitionScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	handleCancel = e => {
		e.preventDefault();
		this.props.dispatch(navigate("/"));
	};

	handleNavigatePlayer = e => {
		e.preventDefault();
		this.props.dispatch(navigate("/sign-up"));
	};

	handleNavigateSeller = e => {
		e.preventDefault();
		this.props.dispatch(navigate("/sign-up-seller"));
	};

	render() {
		const { navigation, location } = this.props;

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}

		return (
			<div className={styles.container}>
				<div className={styles.logoContainer}>
					<Image src={"/logo.png"} className={styles.logo} />
				</div>
				<div className={styles.buttonsContainer}>
					<Button className={styles.button} onClick={this.handleNavigatePlayer}>
						<Icon name={"user plus"} />
						<div className={styles.text}>{getLabel("Player")}</div>
					</Button>
					{/* <Button className={styles.button2} onClick={this.handleNavigateSeller}>
						<Icon name={"user plus"} />
						<div className={styles.text}>{getLabel("Seller")}</div>
					</Button> */}
					<div className={styles.signUpText} onClick={this.handleCancel}>
						<div className={styles.hyperlink}>{getLabel("Cancel")}</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ navigation }) {
	return { navigation };
}

export default withRouter(connect(mapStateToProps)(TransitionScreen));
