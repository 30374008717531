import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Select, Flag } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import { changeLanguage } from "../../redux/actions/settings";
import { endNavigate } from "../../redux/actions/navigation";
import { find } from "lodash";

import styles from "./SettingsScreen.module.css";

class SettingsScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	handleLanguageChange = (e, { value }) => {
		e.preventDefault();
		const { language } = this.props.settings;
		if (value !== language) {
			this.props.dispatch(changeLanguage(value));
		}
	};

	findFlag = (array, language) => {
		const item = find(array, { value: language });
		return item.flag;
	};

	render() {
		const { settings } = this.props;

		const languages = [
			{ text: "English", value: "english", flag: "us" },
			{ text: "Français", value: "french", flag: "fr" },
			{ text: "Kreyòl", value: "creole", flag: "ht" }
		];

		return (
			<Container title={getLabel("Settings")}>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Language")}</div>
					<div className={styles.flag}>
						<Flag name={this.findFlag(languages, settings.language)} />
						<Select
							options={languages}
							onChange={this.handleLanguageChange}
							value={settings.language}
							className={styles.select}
							flag={this.findFlag(languages, settings.language)}
						/>
					</div>
				</div>
			</Container>
		);
	}
}

function mapStateToProps({ navigation, settings }) {
	return { navigation, settings };
}

export default connect(mapStateToProps)(SettingsScreen);
