import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Image } from "semantic-ui-react";
import CountDown from "../../components/Countdown/Countdown";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import Prizes from "../../components/Prizes/Prizes";
import { getPrizes } from "../../redux/actions/prize";
import { getAllUser } from "../../redux/actions/user";
import { getCart } from "../../redux/actions/cart";
import { endNavigate, navigate } from "../../redux/actions/navigation";

import styles from "./HomeScreen.module.css";

class HomeScreen extends PureComponent {
  constructor(props) {
    super(props);

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = async () => {
    await this.props.dispatch(getAllUser());
    await this.props.dispatch(getPrizes());
    await this.props.dispatch(getCart(this.props.user._id));
  
  };

  renderPrizes = (array) => {
    if (array !== undefined) {
      const prize = array.map((item, index) => {
        return <Prizes key={index} placement={item.place} prize={item.prize} />;
      });
      return prize;
    }
    return null;
  };

  getCountdownTime = (fecha, hora) => {
    if (fecha !== undefined && hora !== undefined) {
      const xDate = fecha.split("/");
      const xHour = hora.split(":");
      const finishDate = new Date(xDate[2], xDate[1] - 1, xDate[0], xHour[0], xHour[1], 0);
      return finishDate;
    }
    return 0;
  };

  handleAddBoulpik = () => {
    this.props.dispatch(navigate("/my-boulpik/choose-draw"));
  };

  render() {
    const { user, prize, navigation, location } = this.props;

    if (navigation.navigate && location.pathname !== navigation.route) {
      return <Redirect push to={navigation.route} />;
    }

    return (
      <Container title={getLabel("WeeklyPrizes")}>
        <Image src={"/logo.png"} size={"small"} centered className={styles.spacer} />
        <CountDown
          until={this.getCountdownTime(prize.prizes.fecha, prize.prizes.hour)}
          onComplete={() => this.props.dispatch(getPrizes())}
        />
        {user.role!=="Super" &&
        <Button
          color={"red"}
          icon={"plus"}
          content={getLabel("Buy") + " Boulcash"}
          style={user.role === "User" || "Detaillants" ? { display: "flex" } : { display: "none" }}
          className={styles.button}
          onClick={this.handleAddBoulpik}
        /> }
        {user.role==="Super" && 
          <div className={styles.text}>Nombres Joueurs : {user.totalJoueur}</div>
        }
        {user.role!=="Super" &&
        <div className={styles.text}>{getLabel("PrizeDraw") + ": " + prize.prizes.fecha}</div>}
        {this.renderPrizes(prize.prizes.arrayPosicion)}
      </Container>
    );
  }
}

function mapStateToProps({ user, prize, navigation }) {
  //console.log("user : ",user);
  return { user, prize, navigation };
}

export default withRouter(connect(mapStateToProps)(HomeScreen));
