import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";

import styles from "./Input.module.css";

class Input extends PureComponent {
	render() {
		const { name, value, placeholder, onChange, icon, type, style, disabled } = this.props;

		return (
			<div className={styles.inputContainer} style={style}>
				<Icon name={icon} className={styles.icon} />
				<input
					disabled={disabled}
					className={styles.input}
					placeholder={placeholder}
					name={name}
					type={type}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}
}

export default Input;
