import { NAVIGATE, END_NAVIGATE } from "../actions/types";

export default function navigation(state = { route: "", navigate: false }, action) {
	const { type, payload } = action;
	switch (type) {
		case NAVIGATE:
			return { ...state, route: payload.route, navigate: true };

		case END_NAVIGATE:
			return { ...state, navigate: false };

		default:
			return state;
	}
}
