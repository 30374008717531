import { DISMISS_SUCCESS, ON_SUCCESS } from "../actions/types";
import { getSuccessMessage } from "../../constants/Success";

export default function success(
	state = { success: false, successCode: "", successTitle: "", successText: "" },
	action
) {
	const { type, payload } = action;
	let successConstants = {};
	switch (type) {
		case ON_SUCCESS:
			successConstants = getSuccessMessage(payload.data, payload.language);
			return {
				...state,
				success: true,
				successCode: payload.data,
				successTitle: successConstants.title,
				successText: successConstants.text
			};

		case DISMISS_SUCCESS:
			return { ...state, success: false, successCode: "", successTitle: "", successText: "" };

		default:
			return state;
	}
}
