import React, { PureComponent } from "react";
import { Sidebar } from "semantic-ui-react";
import { connect } from "react-redux";
import { getMenu } from "../../constants/Menu";
import { WEB, WIDTH, HEIGHT } from "../../constants/Layout";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";

import styles from "./Container.module.css";

class Container extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			dimmed: false
		};
		this.wrapperRef = React.createRef();
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleClickOutside);
	};

	setWrapperRef = node => {
		this.wrapperRef = node;
	};

	handleClickOutside = event => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ dimmed: false, visible: false });
		}
	};

	handleShowHideClick = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState(prevState => ({ dimmed: !prevState.dimmed, visible: !prevState.visible }));
	};

	renderChildren = children => {
		return (
			<div className={styles.content} style={{ minHeight: HEIGHT - 60 }}>
				{children}
			</div>
		);
	};

	render() {
		const { children, title, nested } = this.props;
		const { visible, dimmed } = this.state;
		const menuOptions = getMenu(WEB);
		return (
			<Sidebar.Pushable className={styles.container}>
				<Sidebar
					style={{ width: WIDTH * 0.8 }}
					className={styles.menuContainer}
					animation={"overlay"}
					visible={visible}
				>
					<div ref={this.setWrapperRef} className={styles.menu}>
						<Menu items={menuOptions} toggleMenu={this.handleShowHideClick} />
					</div>
				</Sidebar>
				<Sidebar.Pusher dimmed={dimmed} className={styles.contentContainer}>
					<Header title={title} toggleMenu={this.handleShowHideClick} nested={nested} />
					{this.renderChildren(children)}
				</Sidebar.Pusher>
			</Sidebar.Pushable>
		);
	}
}

function mapStateToProps({ user, loading }) {
	return { user, loading };
}

export default connect(mapStateToProps)(Container);
