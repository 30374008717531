import React, { PureComponent } from "react";
import { getLabel } from "../../constants/Strings";
import { currencyFormat } from "../../constants/Utils";
import styles from "./DetailDrawItem.module.css";

class DetailDrawItem extends PureComponent {
	render() {
		const { tirageDate, totalPlayed, countPlayer, price, maxBought, playerName } = this.props;
		let amount = totalPlayed*price;
		return (
			<div className={styles.container}>
				<div className={styles.topSide}>
					<div className={styles.infoContainer}>
						<div className={styles.titleText}>{getLabel("Draw") + " " + tirageDate}</div>
						<div className={styles.titleText}>{getLabel("BoulpikPlayed") + " : " + totalPlayed}</div>
						<div className={styles.titleText}>{getLabel("Participant") + " : " + countPlayer}</div>
						<div className={styles.titleText}>{getLabel("Frequency") + " : " + maxBought}</div>
						<div className={styles.titleText}>{getLabel("Nickname") + " : " + playerName}</div>
					</div>
					<div className={styles.priceContainer}>
						<div className={styles.titleText}>{getLabel("Price")}</div>
						<div className={styles.text}>{currencyFormat(price)}</div>
						<div className={styles.textTotal}>{getLabel("Total")}</div>
						<div className={styles.textPrice}>{currencyFormat(amount)}</div>
					</div>
				</div>
				
			</div>
		);
	}
}

export default DetailDrawItem;
