import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import { endNavigate } from "../../redux/actions/navigation";
import Container from "../../components/Container/Container";
import ImageButton from "../../components/ImageButton/ImageButton";

class WithdrawScreen extends PureComponent {
  constructor(props) {
    super(props);

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  handleButtonPress = (type) => {
   // console.log(type);
  };

  render() {
    return (
      <Container title={getLabel("WithdrawalCredit")}>
        <ImageButton type="MonCash" onPress={this.handleButtonPress} />
        <ImageButton type="LajanCash" onPress={this.handleButtonPress} />
        <ImageButton type="CashMobile" onPress={this.handleButtonPress} />
      </Container>
    );
  }
}

function mapStateToProps({ navigation }) {
  return { navigation };
}

export default connect(mapStateToProps)(WithdrawScreen);
