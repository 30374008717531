import React, { PureComponent } from "react";
import styles from "./BoulpikInput.module.css";

class BoulpikInput extends PureComponent {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  focus = () => {
    this.input.current.focus();
  };

  render() {
    const { value, onChange } = this.props;

    return (
      <React.Fragment>
        <div className={styles.container} onClick={this.focus}>
          <div className={styles.boulpik}>
            <div className={styles.text}>{value[0]}</div>
          </div>
          <div className={styles.boulpik}>
            <div className={styles.text}>{value[1]}</div>
          </div>
          <div className={styles.boulpik}>
            <div className={styles.text}>{value[2]}</div>
          </div>
          <div className={styles.boulpik}>
            <div className={styles.text}>{value[3]}</div>
          </div>
          <div className={styles.boulpik}>
            <div className={styles.text}>{value[4]}</div>
          </div>
        </div>
        <input
          ref={this.input}
          className={styles.input}
          value={value}
          onChange={onChange}
          inputMode={"numeric"}
          pattern={"[0-9]*"}
          type={"number"}
        />
      </React.Fragment>
    );
  }
}

export default BoulpikInput;
