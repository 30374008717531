import React, { PureComponent } from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { getLabel } from "../../constants/Strings";
import { isEmpty } from "lodash";
import styles from "./Calendar.module.css";

class Calendar extends PureComponent {
	render() {
		const { name, value, onChange, markedDates } = this.props;
		if (isEmpty(markedDates)) {
			return (
				<div className={styles.container}>
					<div className={styles.legend}>
						<div className={styles.redCircle} />
						{getLabel("DrawDates")}
					</div>
					<DateInput inline name={name} value={value} onChange={onChange} />
				</div>
			);
		} else {
			return (
				<div className={styles.container}>
					<div className={styles.legend}>
						<div className={styles.redCircle} />
						{getLabel("DrawDates")}
					</div>
					<DateInput inline name={name} value={value} onChange={onChange} marked={markedDates} markColor={"red"} />
				</div>
			);
		}
	}
}

export default Calendar;
