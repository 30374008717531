import React, { PureComponent } from "react";
import styles from "./InvestorDetail.module.css";
import { getLabel } from "../../constants/Strings";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class InvestorDetail extends PureComponent {
	render() {
		const { draw } = this.props;
		const { item } = this.props;
		const benefit =(0.6 *draw?.detailsdraws?.balanceTotal ?? 0 *1)/100 ?? 0;
		const gains = item.percent*1* benefit;
	

	
		return (
			<div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("FullName")}</div>
					<div className={styles.text}> {item?.nom }</div>
				</div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Percent")}</div>
					<div className={styles.text}> {" [" + item?.percent + "%]"}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Address")}</div>
					<div className={styles.text}> {item?.address }</div>
				</div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Email")}</div>
					<div className={styles.text}> {item?.email }</div>
				</div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Phone")}</div>
					<div className={styles.text}> {item?.tel }</div>
				</div>

				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Earnings")}</div>
					<div className={styles.text}> {gains}</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps({ share, user, history, navigation,draw }) {
	return { share, user, history, navigation,draw };
}
  
export default withRouter(connect(mapStateToProps)(InvestorDetail));

