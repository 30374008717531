import React, { PureComponent } from "react";
import { getLabel } from "../../constants/Strings";
import { endNavigate } from "../../redux/actions/navigation";
import { Divider } from "semantic-ui-react";

//Redux
import { connect } from "react-redux";
import { startMoncash } from "../../redux/actions/transactions";

//Components
import Container from "../../components/Container/Container";
import ImageButton from "../../components/ImageButton/ImageButton";
import Input from "../../components/Input/Input";

//styles
import styles from "./RechargeScreen.module.css";

class RechargeScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      credit: "",
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
    this.windowRef = null;
  }

  handleButtonPress = async (type) => {
    const { credit } = this.state;
    if (type === "MonCash") {
      if (credit !== "") {
        this.windowRef = window.open();
        this.props.dispatch(startMoncash(credit, this.windowRef));
      }
    }
  };

  onInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { credit } = this.state;
    return (
      <Container title={getLabel("RechargeCredit")}>
        <div className={styles.inputsContainer}>
          <Input
            icon={"credit card"}
            name={"credit"}
            type={"number"}
            value={credit}
            placeholder={getLabel("CreditToRechage")}
            onChange={this.onInputChange}
          />
        </div>
        <Divider horizontal className={styles.divider}>
          {getLabel("RechargeWith")}
        </Divider>
        <ImageButton type={"MonCash"} onPress={this.handleButtonPress} />
        {/* <ImageButton type={"LajanCash"} onPress={this.handleButtonPress} />
				<ImageButton type={"CashMobile"} onPress={this.handleButtonPress} /> */}
      </Container>
    );
  }
}

function mapStateToProps({ navigation }) {
  return { navigation };
}

export default connect(mapStateToProps)(RechargeScreen);
