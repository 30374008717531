import { combineReducers } from "redux";

//Reducers
import loading from "./loading";
import user from "./user";
import navigation from "./navigation";
import prize from "./prize";
import share from "./share";
import boulpik from "./boulpik";
import history from "./history";
import settings from "./settings";
import transactions from "./transactions";
import cart from "./cart";
import draw from "./draw";
import dealers from "./dealers";
import error from "./error";
import success from "./success";
import city from "./city";
import notification from "./notifications";
import investor from "./investor";

export default combineReducers({
	loading,
	user,
	navigation,
	prize,
	share,
	boulpik,
	history,
	settings,
	transactions,
	cart,
	draw,
	dealers,
	error,
	success,
	city,
	notification,
	investor
});
