import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import { Redirect, withRouter } from "react-router-dom";

import { endNavigate } from "../../redux/actions/navigation";
import { getHistory } from "../../redux/actions/history";
import {  currencyFormat } from "../../constants/Utils";

import styles from "./BenefitScreen.module.css";
import { getSharePercent } from "../../redux/actions/share";
import {getDetailsDraws} from "../../redux/actions/draw";

class ShareScreen extends PureComponent {
  constructor(props) {
    super(props);

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = async () => {
    await this.props.dispatch(getSharePercent());
    await this.props.dispatch(getHistory());
    await this.props.dispatch(getDetailsDraws());
  };

  render() {
    const { navigation, user, location, draw } = this.props;
    const benefit =(0.6 *draw?.detailsdraws?.balanceTotal ?? 0 *1)/100 ?? 0;

    if (navigation.navigate && location.pathname !== navigation.route) {
      return <Redirect push to={navigation.route} />;
    }
    return (
      <Container title={getLabel("Earnings")}>
        <div className={styles.card}>
          <div className={styles.text}>{getLabel("YourBalance")}</div>
          <div className={styles.text}>{currencyFormat(parseInt(user.percent) *benefit) }</div>
        </div>
      </Container>
    );
  }
}

function mapStateToProps({ share, user, history, navigation,draw }) {
  return { share, user, history, navigation,draw };
}

export default withRouter(connect(mapStateToProps)(ShareScreen));
