import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, GET_DRAW, CREATE_DRAW, GET_DETAIL_DRAW, SENT_EMAIL } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";
import { onSuccess } from "./success";

function requestSuccess(type, data) {
  return { type, payload: { data } };
}

function requestFail(errorCode, language) {
  return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getDraws() {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.get("/listGenerateBoulpik")
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_DRAW, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}
export function getListDraws() {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.get("/listDrawBoulcash")
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_DRAW, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function getDetailsDraws() {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.get("/getBoulcashplayedDetails")
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_DETAIL_DRAW, response.data.data));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}



export function sendEmailPDf(endDate, start, end, email) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/sendPdfOfBoughtBoulcashByDate", {
      endDate,
      start,
      end,
      email
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(SENT_EMAIL, response.data.data));
          dispatch(apiEnd());
          dispatch(onSuccess("Email_Sent", language));
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function createDraw(date, price, initial, hour) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/createTirage", {
      end: date,
      price,
      initial,
      hour,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(CREATE_DRAW, response.data.data));
          dispatch(apiEnd());
          dispatch(onSuccess("Create_Draw", language));
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function updateDraw(date, initial, hour) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/updateTirageByDate", {
      end: date,
      initial,
      hour,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_DRAW, response.data.data));
          dispatch(apiEnd());
          dispatch(onSuccess("Update_Draw", language));
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}





export function setWinners(date, boulcashOne, boulcashTwo, boulcashThree, boulcashFour, boulcashFive) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/setWinnersDraw", {
      fecha: date,
      boulcashOne, 
      boulcashTwo, 
      boulcashThree, 
      boulcashFour, 
      boulcashFive
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_DRAW, response.data.data));
          dispatch(apiEnd());
          dispatch(onSuccess("Update_Draw", language));
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}


