import { GET_DEALERS } from "../actions/types";

export default function dealers(state = { authorizedDealers: [], sellers: []}, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_DEALERS:
			return { ...state, authorizedDealers: payload.data.AuthUsers, sellers: payload.data.DetaillantsUsers };

		default:
			return state;
	}
}
