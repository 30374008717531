import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import { getDraws } from "../../redux/actions/draw";
import { endNavigate } from "../../redux/actions/navigation";
import { Button, Icon } from "semantic-ui-react";
import { updateDraw } from "../../redux/actions/draw";
import DropdownDraw from "../../components/DropdownDraw/DropdownDraw";
import Container from "../../components/Container/Container";
//import Calendar from "../../components/Calendar/Calendar";
import Input from "../../components/Input/Input";

import styles from "./UpdateDrawScreen.module.css";

class UpdateDrawScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dateOfDraw: "",
      initial: "",
      hour: "12:00",
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  handleDropdownChange = (e, { value }) => {
    e.preventDefault();
    this.setState({ dateOfDraw: value });
  };

  componentDidMount = async () => {
    await this.props.dispatch(getDraws());
  };

  processDraws = (draw) => {
    let dates = [];
    if (draw !== undefined) {
      draw.forEach((item) => {
        const dateString = item.end.split("/");
        const dd = dateString[0];
        const mm = dateString[1] - 1;
        const yyyy = dateString[2];
        const newDate = new Date(yyyy, mm, dd);
        dates.push(newDate);
      });
      return dates;
    }
    return null;
  };

  handleDrawChange = (e, { value }) => {
    this.setState({ value });
  };

  onInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleUpdateDraw = async () => {
    const { initial, hour, dateOfDraw } = this.state;

    await this.props.dispatch(updateDraw(dateOfDraw, initial, hour));
    this.setState({dateOfDraw: "", initial: "", hour: "" });
  };

  render() {
    const { draw } = this.props;
    const { initial, hour, dateOfDraw } = this.state;
    const disabled = dateOfDraw === "" || initial === "";
    return (
      <Container title={getLabel("UpdateDraw")}>
        {/* <Calendar markedDates={this.processDraws(draw.draws)} value={value} onChange={this.handleDrawChange} /> */}
        <div className={styles.inputsContainer}>
          <DropdownDraw
            options={draw.draws}
            icon={"calendar"}
            placeholder={getLabel("Draw")}
            value={dateOfDraw}
            onChange={this.handleDropdownChange}
          />
          <Input
            icon={"clock"}
            name={"hour"}
            type={"time"}
            value={hour}
            placeholder={getLabel("Hour")}
            onChange={this.onInputChange}
          />
          <Input
            icon={"trophy"}
            name={"initial"}
            type={"number"}
            value={initial}
            placeholder={getLabel("Initial")}
            onChange={this.onInputChange}
          />
        </div>
        <Button className={styles.button} onClick={this.handleUpdateDraw} disabled={disabled}>
          <Icon name={"calendar plus"} />
          <div className={styles.text}>{getLabel("UpdateDraw")}</div>
        </Button>
      </Container>
    );
  }
}

function mapStateToProps({ draw, navigation }) {
  return { draw, navigation };
}

export default connect(mapStateToProps)(UpdateDrawScreen);
