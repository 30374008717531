import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import {
	API_FAILURE,
	DELETE_CART,
	BUY_ALL_FROM_CART,
	GET_CART,
	BUY_ONE_FROM_CART,
	REFRESH_CREDIT,
	GET_NOTIFICATIONS
} from "./types";
import { getCurrentLanguage } from "../../constants/Utils";
import { onSuccess } from "./success";

function requestSuccess(type, data) {
	return { type, payload: { data } };
}

function requestFail(errorCode, language) {
	return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getCart(userID) {
	const language = getCurrentLanguage();
	return dispatch => {
		dispatch(apiStart());
		Api.get(`/users/${userID}`)
			.then(response => {
				if (response.data.success) {
					if (response.data.data._dataInfo.carrito !== undefined) {
						dispatch(requestSuccess(GET_CART, response.data.data._dataInfo.carrito));
					}
					dispatch(requestSuccess(GET_NOTIFICATIONS, response.data.data.user.message));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function deleteFromCart(date, boulpik) {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.delete("/deleteBoulpikCarrito", {
			data: { fecha: date, boulpik }
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(DELETE_CART, response.data.data));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function buyAllFromCart(cartItems) {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/GenerateArrayBoulpik", {
			arrayNumber: cartItems
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(BUY_ALL_FROM_CART, null));
					dispatch(requestSuccess(REFRESH_CREDIT, response.data.data.credit));
					dispatch(apiEnd());
					dispatch(onSuccess("Boulpik_Buy_Multiple", language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function buyOneFromCart(cartItem) {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/GenerateArrayBoulpik", {
			arrayNumber: [cartItem]
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(BUY_ONE_FROM_CART, cartItem));
					dispatch(requestSuccess(REFRESH_CREDIT, response.data.data.credit));
					dispatch(apiEnd());
					dispatch(onSuccess(response.data.message, language));
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}
