import { NAVIGATE, END_NAVIGATE } from "./types";

function requestSuccess(type, route) {
	return { type, payload: { route } };
}

export function navigate(route) {
	return dispatch => {
		dispatch(requestSuccess(NAVIGATE, route));
	};
}

export function endNavigate() {
	return dispatch => {
		dispatch(requestSuccess(END_NAVIGATE, null));
	};
}
