import React, { PureComponent } from "react";
import styles from "./DetailDraw.module.css";
import { getLabel } from "../../constants/Strings";
import { currencyFormat } from "../../constants/Utils";
import { reduce } from "lodash";

class DetailDraw extends PureComponent {
	render() {
		const { item } = this.props;
		const prizeTotal = reduce(
			item.arrayWinner,
			(sum, currentItem) => {
				return sum + parseInt(currentItem.montant);
			},
			0
		);
		const diff = prizeTotal - parseInt(item.initial);
		const played = item.total * parseInt(item.price);
		const benefits = played - diff;
		return (
			<div>
				{/* <div className={styles.row}>
          <div className={styles.text}>{getLabel("Initial")}</div>
          <div className={styles.text}>{currencyFormat(item.initial)}</div>
        </div> */}
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("BoulpikPlayed")}</div>
					<div className={styles.text}>{item.total}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("PriceOf")}</div>
					<div className={styles.text}>{currencyFormat(item.price)}</div>
				</div>
				{/* <div className={styles.row}>
          <div className={styles.text}>{getLabel("PrizeDraw")}</div>
          <div className={styles.text}>{currencyFormat(prizeTotal)}</div>
        </div> */}
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("EarningsOf")}</div>
					<div className={styles.text}>{currencyFormat(benefits)}</div>
				</div>
			</div>
		);
	}
}

export default DetailDraw;
