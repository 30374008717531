import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { endNavigate } from "../../redux/actions/navigation";
import { createDealer } from "../../redux/actions/user";
import { getCities, getZones } from "../../redux/actions/city";
import Container from "../../components/Container/Container";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import DropdownInput from "../../components/DropdownInput/DropdownInput";
import Input from "../../components/Input/Input";
import styles from "./CreateDealerScreen.module.css";

class CreateDealerScreen extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			email: "",
			phone: "",
			city: "",
			zone: "",
			password: "",
			address: "",
			matricule: ""
		};

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = () => {
		this.props.dispatch(getCities());
	};

	handleSignUp = async () => {
		const { name, email, phone, city, zone, password, address, matricule } = this.state;
		await this.props.dispatch(createDealer(name, email, phone, city, zone, password, address, matricule));
		this.setState({
			name: "",
			email: "",
			phone: "",
			city: "",
			password: "",
			address: "",
			matricule: "",
			zone: ""
		});
	};

	onInputChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleDropdownChange = async (e, { value, name }) => {
		e.preventDefault();
		if (name === "city") {
			if (value === "") {
				this.setState({ city: value, zone: "" });
			} else {
				await this.props.dispatch(getZones(value));
				this.setState({ [name]: value, zone: "" });
			}
		} else {
			this.setState({ [name]: value });
		}
	};

	render() {
		const { name, phone, city, password, email, zone, address, matricule } = this.state;
		return (
			<Container title={getLabel("CreateDealer")}>
				<div className={styles.inputsContainer}>
					<Input
						icon={"user"}
						name={"name"}
						value={name}
						placeholder={getLabel("FullName")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"mail"}
						name={"email"}
						value={email}
						placeholder={getLabel("Email")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"phone"}
						name={"phone"}
						type={"number"}
						value={phone}
						placeholder={getLabel("Phone")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"hashtag"}
						name={"matricule"}
						type={"number"}
						value={matricule}
						placeholder={getLabel("Matricule")}
						onChange={this.onInputChange}
					/>
					<DropdownInput
						options={this.props.city.cities}
						icon={"map marker alternate"}
						name={"city"}
						placeholder={getLabel("City")}
						value={city}
						onChange={this.handleDropdownChange}
					/>
					<DropdownInput
						disabled={city === ""}
						options={this.props.city.zones}
						icon={"map pin"}
						name={"zone"}
						placeholder={getLabel("Sector")}
						value={zone}
						onChange={this.handleDropdownChange}
					/>
					<Input
						icon={"map"}
						name={"address"}
						value={address}
						placeholder={getLabel("Address")}
						onChange={this.onInputChange}
					/>
					<PasswordInput
						icon={"lock"}
						name={"password"}
						value={password}
						placeholder={getLabel("Password")}
						onChange={this.onInputChange}
					/>
				</div>
				<div className={styles.buttonsContainer}>
					<Button className={styles.button} onClick={this.handleSignUp}>
						<Icon name={"user plus"} />
						<div className={styles.text}>{getLabel("CreateDealer")}</div>
					</Button>
				</div>
			</Container>
		);
	}
}

function mapStateToProps({ user, navigation, city }) {
	return { user, navigation, city };
}

export default connect(mapStateToProps)(CreateDealerScreen);
