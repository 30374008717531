import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { getLabel } from "../../constants/Strings";
import { getHistory } from "../../redux/actions/history";
import Container from "../../components/Container/Container";
import { Redirect, withRouter } from "react-router-dom";

import { endNavigate } from "../../redux/actions/navigation";

import styles from "./ShareScreen.module.css";
import { Divider } from "semantic-ui-react";
import { getSharePercent } from "../../redux/actions/share";
import {getDetailsDraws} from "../../redux/actions/draw";

class ShareScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		await this.props.dispatch(getSharePercent());
		await this.props.dispatch(getHistory());
		await this.props.dispatch(getDetailsDraws());
	};

	render() {
		const { user, share, navigation, location, draw } = this.props;
		let {gainAss =0 ,compte = 0,imprevu =0} = share?.shares;
		
		
		const benefit =(draw?.detailsdraws?.balanceTotal ?? 0 *1)/100 ?? 0;
		console.log("benefit : ",draw?.detailsdraws?.balanceTotal);
		

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}
		return (
			<Container title={getLabel("Share")}>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Benefit")}</div>
					{"[" + gainAss + "%]"}
					<div className={styles.percent}>
						{parseInt(gainAss) * benefit}
					</div>
				</div>
				<Divider section />
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("PercentEntr")}</div>
					{"[" + 15 + "%]"}
					<div className={styles.percent}>
						{parseInt(15) * benefit}
					</div>
				</div>
				<Divider section />
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("PercentDev")}</div>
					{"[" + 10 + "%]"}
					<div className={styles.percent}>
						{parseInt(10) * benefit}
					</div>
				</div>
				<Divider section />
				<div className={styles.row}>
					<div className={styles.textCompteOff}>{getLabel("AccountOff")}</div>
					{" [" + compte + "%]"}
					<div className={styles.percent}>{parseInt(compte) * benefit}</div>
				</div>
				<Divider section />

				<div className={styles.row}>
					<div className={styles.textImprevu}>{getLabel("Unexpected")}</div>
					{"[" + imprevu +"%]"}
					<div className={styles.percent}>
						{parseInt(imprevu) *benefit}
					</div>
				</div>

				<Divider section />
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Earnings")}</div>
					<div className={styles.percent}>{parseInt(user.percent) * (parseInt(gainAss)/100) * benefit}</div>
				</div>
			</Container>
		);
	}
}

function mapStateToProps({ user, share, history, navigation,draw }) {
	console.log("share : ",share);
	return { share, user, history, navigation,draw };
}

export default withRouter(connect(mapStateToProps)(ShareScreen));
