import { GET_PRIZES } from "../actions/types";

export default function prizes(state = { prizes: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_PRIZES:
			return { ...state, prizes: payload.data };

		default:
			return state;
	}
}
