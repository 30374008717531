import { CREATE_BOULPIK, RESET_BOULPIK, GET_BOULPIKS, AUTO_BOULPIK, INFO_BOULPIKS } from "../actions/types";

export default function boulpik(state = { boulpiks: [], date: "", price: 0, boulpik: "",info:[] }, action) {
	const { type, payload } = action;
	switch (type) {
		case CREATE_BOULPIK:
			return { ...state, date: payload.date, price: payload.price };

		case RESET_BOULPIK:
			return { ...state, boulpik: "" };

		case GET_BOULPIKS:
			return { ...state, boulpiks: payload.data };
		
		case INFO_BOULPIKS:
			return { ...state, info: payload.data };

		case AUTO_BOULPIK:
			return { ...state, boulpik: payload.boulpik };

		default:
			return state;
	}
}
