import { CHANGE_LANGUAGE, REHYDRATE_SETTINGS } from "../actions/types";

export default function settings(state = { language: "french" }, action) {
	const { type, payload } = action;
	switch (type) {
		case REHYDRATE_SETTINGS:
			return { ...state, language: payload.data };

		case CHANGE_LANGUAGE:
			return { ...state, language: payload.data };

		default:
			return state;
	}
}
