const Errors = {
	values: {
		"0000": {
			english: {
				title: "Network Error!",
				text: "Please check your network connection and try again.",
			},
			french: {
				title: "Erreur réseau!",
				text: "Veuillez vérifier votre connexion réseau et réessayer.",
			},
			creole: {
				title: "Problem Entenet!",
				text: "Tanpri Verifye Koneksyon Entenet ou epi eseye anko.",
			},
		},
		"11000": {
			english: {
				title: "Network Error!",
				text: "Please check your network connection and try again.",
			},
			french: {
				title: "Erreur réseau!",
				text: "Veuillez vérifier votre connexion réseau et réessayer.",
			},
			creole: {
				title: "Problem Entenet!",
				text: "Tanpri Verifye Koneksyon Entenet ou epi eseye anko.",
			},
		},
		"0001": {
			english: {
				title: "Authentication Failed!",
				text: "Email or Phone not found. Please try again.",
			},
			french: {
				title: "Authentification échouée!",
				text: "Email ou téléphone non trouvé. Veuillez réessayer.",
			},
			creole: {
				title: "Otantifikasyon Echoue!",
				text: "Nou pa Jwen imel oubyen Telefon sa. Tanpri Verifye epi Eseye anko.",
			},
		},
		"0002": {
			english: {
				title: "Error",
				text: "Missing Token.",
			},
			french: {
				title: "Erreur",
				text: "Jeton manquant.",
			},
			creole: {
				title: "Erè",
				text: "Jeton Manke.",
			},
		},
		"0707": {
			english: {
				title: "Sign Up Failed!",
				text: "Email is already in use.",
			},
			french: {
				title: "L'inscription a échoué!",
				text: "Cet email est déjà utilisé.",
			},
			creole: {
				title: "Echek Enskripsyon!",
				text: "imel sa itilize deja.",
			},
		},
		"0007": {
			english: {
				title: "Sign Up Failed!",
				text: "Phone is already in use.",
			},
			french: {
				title: "L'inscription a échoué!",
				text: "Le téléphone est déjà utilisé.",
			},
			creole: {
				title: "Echek Enskripsyon!",
				text: "Nimero Telefon sa itilize deja.",
			},
		},
		"0003": {
			english: {
				title: "Authentication Failed!",
				text: "You have been suspended. Please contact admin for more information.",
			},
			french: {
				title: "Authentification échouée!",
				text: "Vous avez été suspendu. S'il vous plaît contacter admin pour plus d'informations.",
			},
			creole: {
				title: "Otantifikasyon Echoue!",
				text: "Yo Bloke Kont ou an. Tanpri Kontakte yon responsab pou plis enfomasyon.",
			},
		},
		"0004": {
			english: {
				title: "Authentication Failed!",
				text: "Wrong password, please try again or click Forgot Password to reset it.",
			},
			french: {
				title: "Authentification échouée!",
				text: "Mot de passe incorrect, veuillez réessayer ou cliquez sur Mot de passe oublié pour le réinitialiser.",
			},
			creole: {
				title: "Otantifikasyon Echoue!",
				text: "Move Modpas, Tanpri eseye Anko oubyen klike sou Bliye Modpas pou ka chanjel.",
			},
		},
		"0207": {
			english: {
				title: "Failure Adding to Cart!",
				text: "You can't have more than 10 boulpiks in your cart for the same draw.",
			},
			french: {
				title: "Échec de l'ajout au panier!",
				text: "Vous ne pouvez pas avoir plus de 10 boulpiks dans votre panier pour le même tirage.",
			},
			creole: {
				title: "Erè nan Ajoute nan Charyo a",
				text: "Ou paka achte plis ke 30 Boulcash nan menm tiraj la.",
			},
		},
		"0202": {
			english: {
				title: "Failure Buying Boulcash!",
				text: "You can't buy more than 30 boulpiks for the same draw.",
			},
			french: {
				title: "Échec Acheter Boulcash!",
				text: "Vous ne pouvez pas acheter plus de 30 boulpiks pour le même tirage.",
			},
			creole: {
				title: "Échek Acha Boulcash!",
				text: "Ou paka achte plis ke 30 Boulcash nan menm tiraj la.",
			},
		},
		"0205": {
			english: {
				title: "Failure Adding to Cart!",
				text: "You have already added that boulcash to your cart.",
			},
			french: {
				title: "Échec de l'ajout au panier!",
				text: "Vous avez déjà ajouté ce boulcash à votre panier.",
			},
			creole: {
				title: "Erè nan Ajoute nan Charyo a",
				text: "Ou mete Boulcash sa nan Charyo ou deja.",
			},
		},
		"0206": {
			english: {
				title: "Failure Buying Boulcash!",
				text: "You have already bought that boulcash.",
			},
			french: {
				title: "Échec d'achat Boulcash!",
				text: "Vous avez déjà acheté ce boulcash.",
			},
			creole: {
				title: "Échek Acha Boulcash!",
				text: "Ou achte Boulcash sa Deja",
			},
		},
		"0209": {
			english: {
				title: "Failure Buying Boulcash!",
				text: "This boulcash has already been played by 3 other players.",
			},
			french: {
				title: "Échec d'achat Boulcash!",
				text: "Ce boulcash a déjà été joué par 3 autres joueurs.",
			},
			creole: {
				title: "Échek Acha Boulcash!",
				text: "Gen 3 lot moun ki achte Boulcash sa.",
			},
		},
		"0010": {
			english: {
				title: "Incorrect Pin!",
				text: "Incorrect Pin, try again.",
			},
			french: {
				title: "Pin Incorrect!",
				text: "Pin Incorrect, Reessayer avec un autre Pin .",
			},
			creole: {
				title: "Échek Pin!",
				text: "Pin sa pa bon, eseye avek yon lot.",
			},
		},
		"0300": {
			english: {
				title: "Failure Buying Boulcash!",
				text: "your Credit does not enough.",
			},
			french: {
				title: "Échec d'achat Boulcash!",
				text: "Votre Balance est insufisant.Recharger votre compte",
			},
			creole: {
				title: "Échek Acha Boulcash!",
				text: "Balans ou pa ase,Tanpri rechaje kont ou.",
			},
		},
		"0301": {
			english: {
				title: "Failure Buying Boulcash!",
				text: "You can t buy this boulcash again.",
			},
			french: {
				title: "Échec d'achat Boulcash!",
				text: "Vous avez deja ce boulcash.",
			},
			creole: {
				title: "Échek Acha Boulcash!",
				text: "ou achte boulcash sa deja.",
			},
		},
		"0211": {
			english: {
				title: "Phone Number  Error!",
				text: "phone number not found, try again.",
			},
			french: {
				title: "Erreur avec le numewo!",
				text: "numero non trouvé.Réessayer",
			},
			creole: {
				title: "Échek nimero!",
				text: "pa gen nimewo sa,eseye yn lòt .",
			},
		},
		"0514": {
			english: {
				title: "Not available!",
				text: "MonCash Service doesn't available.",
			},
			french: {
				title: "NO Disponible!",
				text: "Ce Service sera disponible bientôt .",
			},
			creole: {
				title: "Pa disponib!",
				text: "Opsyon sa Poko Disponib.",
			},
		},
	},
};

export function getErrorMessage(code, language) {
	const word = Errors.values[code];
	return word[language];
}
