import { GET_NOTIFICATIONS, DELETE_NOTIFICATIONS, INSERT_NOTIFICATION } from "../actions/types";
import { filter } from "lodash";

export default function notification(state = { notifications: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_NOTIFICATIONS:
			return { ...state, notifications: payload.data };

		case INSERT_NOTIFICATION:
			return { ...state, notifications: [payload.data, ...state.notifications] };

		case DELETE_NOTIFICATIONS:
			const newNotifications = filter(state.notifications, ({ _id }) => _id !== payload.data);
			return { ...state, notifications: newNotifications };

		default:
			return state;
	}
}
