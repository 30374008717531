import { DISMISS_SUCCESS, ON_SUCCESS } from "./types";

export function dismissSuccess() {
	return {
		type: DISMISS_SUCCESS
	};
}

export function onSuccess(data, language) {
	return {
		type: ON_SUCCESS,
		payload: { data, language }
	};
}
