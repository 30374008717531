import React, { PureComponent } from "react";
import { Image } from "semantic-ui-react";
import styles from "./ImageButton.module.css";

class ImageButton extends PureComponent {
	render() {
		const { type, onPress } = this.props;
		switch (type) {
			case "MonCash":
				return (
					<div className={styles.containerMonCash} onClick={() => onPress(type)}>
						<Image src={"/monCash.png"} className={styles.image} />
						<div className={styles.text}>{"Mon Cash"}</div>
					</div>
				);
			case "LajanCash":
				return (
					<div className={styles.containerLajanCash} onClick={() => onPress(type)}>
						<Image src={"/lajanCash.png"} className={styles.image} />
						<div className={styles.text}>{"Lajan Cash"}</div>
					</div>
				);
			case "CashMobile":
				return (
					<div className={styles.containerCashMobile} onClick={() => onPress(type)}>
						<Image src={"/cashMobile.png"} className={styles.image} />
						<div className={styles.cashMobileText}>{"Cash Mobile"}</div>
					</div>
				);
			default:
				return null;
		}
	}
}

export default ImageButton;
