import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Icon, Checkbox, Image } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { registerSeller } from "../../redux/actions/user";
import { getCities, getZones } from "../../redux/actions/city";
import { endNavigate, navigate } from "../../redux/actions/navigation";

//Components
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import Input from "../../components/Input/Input";
import DropdownInput from "../../components/DropdownInput/DropdownInput";
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy";

import styles from "./SignUpSellerScreen.module.css";

class SignUpScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      phone: "",
      address: "",
      city: "",
      zone: "",
      password: "",
      pin: "",
      checked: false,
      modalVisible: false,
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = () => {
    this.props.dispatch(getCities());
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.dispatch(navigate("/register"));
  };

  handlePrivacy = (e) => {
    e.preventDefault();
    this.setState({ modalVisible: true });
  };

  handleClosePrivacy = (e) => {
    e.preventDefault();
    this.setState({ modalVisible: false });
  };

  handleSignUp = async () => {
    const { name, phone, address, city, zone, password, pin } = this.state;
    await this.props.dispatch(registerSeller(name, phone, city, zone, address, password, pin));
  };

  handleInputOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    return this.setState({ [name]: value });
  };

  handleCheckOnChange = (e) => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked });
  };

  handleDropdownChange = async (e, { value, name }) => {
    e.preventDefault();
    if (name === "city") {
      if (value === "") {
        this.setState({ city: value, zone: "" });
      } else {
        await this.props.dispatch(getZones(value));
        this.setState({ [name]: value, zone: "" });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  render() {
    const { name, phone, password, address, pin, city, zone, checked, modalVisible } = this.state;
    const { navigation, location } = this.props;

    if (navigation.navigate && location.pathname !== navigation.route) {
      return <Redirect push to={navigation.route} />;
    }

    const disabled =
      name === "" ||
      phone === "" ||
      city === "" ||
      address === "" ||
      password === "" ||
      zone === "" ||
      pin === "" ||
      !checked
        ? true
        : false;

    return (
      <div className={styles.container}>
        <PrivacyPolicy visible={modalVisible} onClose={this.handleClosePrivacy} />
        <div className={styles.logoContainer}>
          <Image src={"/logo.png"} className={styles.logo} />
        </div>
        <div className={styles.inputsContainer}>
          <Input
            icon={"user"}
            name={"name"}
            value={name}
            placeholder={getLabel("FullName")}
            onChange={this.handleInputOnChange}
          />
          <Input
            icon={"phone"}
            name={"phone"}
            type={"number"}
            value={phone}
            placeholder={getLabel("Phone")}
            onChange={this.handleInputOnChange}
          />
          <DropdownInput
            options={this.props.city.cities}
            icon={"map marker alternate"}
            name={"city"}
            placeholder={getLabel("City")}
            value={city}
            onChange={this.handleDropdownChange}
          />
          <DropdownInput
            disabled={city === ""}
            options={this.props.city.zones}
            icon={"map pin"}
            name={"zone"}
            placeholder={getLabel("Sector")}
            value={zone}
            onChange={this.handleDropdownChange}
          />
          <Input
            icon={"map"}
            name={"address"}
            value={address}
            placeholder={getLabel("Address")}
            onChange={this.handleInputOnChange}
          />
          <PasswordInput
            icon={"lock"}
            name={"password"}
            value={password}
            placeholder={getLabel("Password")}
            onChange={this.handleInputOnChange}
          />
          <PasswordInput
            icon={"key"}
            name={"pin"}
            value={pin}
            placeholder={getLabel("RecoveryPIN")}
            onChange={this.handleInputOnChange}
          />
          <div className={styles.actionsContainer}>
            <Checkbox onChange={this.handleCheckOnChange} checked={checked} className={styles.text} />
            <div className={styles.privacyText}>
              {getLabel("ConfirmSignUp")}{" "}
              <i className={styles.hyperlink} onClick={this.handlePrivacy}>
                {getLabel("PrivacyPolicy")}.
              </i>
            </div>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button className={styles.button} onClick={this.handleSignUp} disabled={disabled}>
            <Icon name={"user plus"} />
            <div className={styles.text}>{getLabel("SignUp")}</div>
          </Button>
          <div className={styles.signUpText} onClick={this.handleCancel}>
            <div className={styles.hyperlink}>{getLabel("Cancel")}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ navigation, city }) {
  return { navigation, city };
}

export default withRouter(connect(mapStateToProps)(SignUpScreen));
