import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import { getAuthorizedDealers } from "../../redux/actions/dealers";
import { endNavigate } from "../../redux/actions/navigation";
import { isEmpty, escapeRegExp, filter } from "lodash";
import { Search } from "semantic-ui-react";
import Container from "../../components/Container/Container";
import DealerItem from "../../components/DealerItem/DealerItem";
import styles from "./AuthorizedDealersScreen.module.css";

class AuthorizedDealersScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: "",
      allDA: [],
      filteredDA: [],
      allSellers: [],
      filteredSellers: [],
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = async () => {
    await this.props.dispatch(getAuthorizedDealers());
    if (this.props.user.role === "User") {
      const allSellers = this.props.dealers.sellers;
      this.setState({ allSellers, filteredSellers: allSellers });
    } else {
      const allDA = this.props.dealers.authorizedDealers;
      this.setState({ allDA, filteredDA: allDA });
    }
  };

  componentDidUpdate = () => {
    const { authorizedDealers, sellers } = this.props.dealers;
    if (this.props.user.role === "User") {
      if (sellers !== this.state.allSellers) {
        this.setState({ allSellers: sellers, filteredSellers: sellers });
      }
    } else {
      if (authorizedDealers !== this.state.allDA) {
        this.setState({ allDA: authorizedDealers, filteredDA: authorizedDealers });
      }
    }
  };

  onSearchChange = (e, { value }) => {
    e.preventDefault();
    const { role } = this.props.user;
    const { allDA, allSellers } = this.state;
    const re = new RegExp(escapeRegExp(value), "i");
    const isMatch = (item) => re.test(item.ville) || re.test(item.zone);
    if (role === "User") {
      this.setState({ filter: value, filteredSellers: filter(allSellers, isMatch) });
    } else {
      this.setState({ filter: value, filteredDA: filter(allDA, isMatch) });
    }
  };

  renderItemList = (data) => {
    if (!isEmpty(data)) {
      const List = data.map((item, index) => {
        return (
          <DealerItem
            name={item.nom}
            city={item.ville}
            zone={item.zone}
            phone={item.tel}
            email={item.email}
            address={item.address}
            key={index}
          />
        );
      });
      return List;
    }
    return null;
  };

  render() {
    const { filter, filteredDA, filteredSellers } = this.state;
    const { role } = this.props.user;
    return (
      <Container title={getLabel(role === "User" ? "SellersList" : "AuthorizedDealers")}>
        <Search
          showNoResults={false}
          input={{ icon: "search", className: styles.fullWidth }}
          fluid
          onSearchChange={this.onSearchChange}
          value={filter}
          className={styles.fullWidth}
          placeholder={getLabel("placeHolderCityState")}
        />
        {this.renderItemList(role === "User" ? filteredSellers : filteredDA)}
      </Container>
    );
  }
}

function mapStateToProps({ dealers, navigation, user }) {
  return { dealers, navigation, user };
}

export default connect(mapStateToProps)(AuthorizedDealersScreen);
