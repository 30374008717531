import React, { PureComponent } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import { endNavigate } from "../../redux/actions/navigation";

import styles from "./SetWinnersScreen.module.css";
import { getDraws } from "../../redux/actions/draw";
import Input from "../../components/Input/Input";
import DropdownDraw from "../../components/DropdownDraw/DropdownDraw";
import { getDetailsDraws } from "../../redux/actions/draw";
import { setWinners } from "../../redux/actions/draw";




class SetWinnersScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dateOfDraw: "",
      boulcashOne: "",
      boulcashTwo: "",
      boulcashThree: "",
      boulcashFour: "",
      boulcashFive: "",
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = async() => {
    await this.props.dispatch(getDraws());
    await this.props.dispatch(getDetailsDraws());
  };

  handleCheckOnChange = (e) => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked });
  };

  handleDropdownChange = (e, { value }) => {
    e.preventDefault();
    this.setState({ dateOfDraw: value });
  };
  handleInputOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    return this.setState({ [name]: value });
  };
  handleSetWinners = async () => {
    const { dateOfDraw, boulcashOne, boulcashTwo, boulcashThree, boulcashFour, boulcashFive } = this.state;
    await this.props.dispatch(setWinners(dateOfDraw, boulcashOne, boulcashTwo, boulcashThree, boulcashFour, boulcashFive));
  };

  render() {
    const {draw, navigation, location } = this.props;
    const { dateOfDraw, boulcashOne, boulcashTwo, boulcashThree, boulcashFour, boulcashFive} = this.state;

    if (navigation.navigate && location.pathname !== navigation.route) {
      return <Redirect push to={navigation.route} />;
    }

    return (
      <Container title={getLabel("SetWinners")}>
         <div className={styles.inputsContainer}>
          <DropdownDraw
              options={draw.draws}
              icon={"calendar"}
              placeholder={getLabel("Draw")}
              value={dateOfDraw}
              onChange={this.handleDropdownChange}
          />

          <Input
            name={"boulcashOne"}
            type={"number"}
            value={boulcashOne}
            placeholder={getLabel("BoulcashOne")}
            onChange={this.handleInputOnChange}
          />
          <Input
            name={"boulcashTwo"}
            type={"number"}
            value={boulcashTwo}
            placeholder={getLabel("BoulcashTwo")}
            onChange={this.handleInputOnChange}
          />
          <Input
            name={"boulcashThree"}
            type={"number"}
            value={boulcashThree}
            placeholder={getLabel("BoulcashThree")}
            onChange={this.handleInputOnChange}
          />
          <Input
            name={"boulcashFour"}
            type={"number"}
            value={boulcashFour}
            placeholder={getLabel("BoulcashFour")}
            onChange={this.handleInputOnChange}
          />
          <Input
            name={"boulcashFive"}
            type={"number"}
            value={boulcashFive}
            placeholder={getLabel("BoulcashFive")}
            onChange={this.handleInputOnChange}
          />
        </div>
        <div className={styles.buttonsContainer}>
					<Button className={styles.button} onClick={this.handleSetWinners}>
						<div className={styles.text}>{getLabel("Ok")}</div>
					</Button>
				</div>
       
      </Container>
    );
  }
}

function mapStateToProps({ user, draw, city, navigation }) {
  return { user, draw,city, navigation };
}

export default withRouter(connect(mapStateToProps)(SetWinnersScreen));
