import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import Draws from "../../components/Draws/Draws";

import { getHistory } from "../../redux/actions/history";

import { endNavigate } from "../../redux/actions/navigation";
import { isEmpty } from "lodash";

class HistoryScreen extends PureComponent {
	constructor(props) {
		super(props);

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		await this.props.dispatch(getHistory());
	};

	renderDraws = (data) => {
		if (!isEmpty(data)) {
			return <Draws data={data} type="prizes" />;
		}
		return null;
	};

	renderDrawList = (data) => {
		if (!isEmpty(data)) {
			return <Draws data={data} type="details" />;
		}
		return null;
	};

	render() {
		const { history, user } = this.props;

		if (user.role !== "Investor") {
			return <Container title={getLabel("History")}>{this.renderDraws(history.history)}</Container>;
		} else {
			return <Container title={getLabel("History")}>{this.renderDrawList(history.history)}</Container>;
		}
	}
}

function mapStateToProps({ user, history, navigation }) {
	return { history, user, navigation };
}

export default connect(mapStateToProps)(HistoryScreen);
