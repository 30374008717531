import React, { PureComponent } from "react";
import { Button } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { currencyFormat, splitNumbers } from "../../constants/Utils";
import styles from "./CartItem.module.css";

class CartItem extends PureComponent {
	renderBoulpiks = numbers => {
		const items = splitNumbers(numbers);
		const boulpiks = items.map((item, index) => {
			return (
				<div className={styles.boulpiks} key={index}>
					<div className={styles.boulpikText}>{item}</div>
				</div>
			);
		});
		return boulpiks;
	};

	render() {
		const { tirageDate, boulpik, price, onPressRemove, onPressBuy } = this.props;
		return (
			<div className={styles.container}>
				<div className={styles.topSide}>
					<div className={styles.infoContainer}>
						<div className={styles.titleText}>{getLabel("Draw") + " " + tirageDate}</div>
						<div className={styles.boulpikContainer}>{this.renderBoulpiks(boulpik)}</div>
					</div>
					<div className={styles.priceContainer}>
						<div className={styles.titleText}>{getLabel("Price")}</div>
						<div className={styles.text}>{currencyFormat(price)}</div>
					</div>
				</div>
				<div className={styles.bottomSide}>
					<Button
						icon={"credit card"}
						color={"green"}
						content={getLabel("BuyNow")}
						className={styles.button}
						onClick={() => onPressBuy(tirageDate, boulpik, price)}
					/>
					<Button
						icon={"trash"}
						color={"red"}
						content={getLabel("Remove")}
						className={styles.button}
						onClick={() => onPressRemove(tirageDate, boulpik)}
					/>
				</div>
			</div>
		);
	}
}

export default CartItem;
