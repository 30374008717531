import React, { PureComponent } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { Icon, Image, Menu, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/user";
import { navigate } from "../../redux/actions/navigation";
import { getLabel } from "../../constants/Strings";
import { currencyFormat, phoneFormat } from "../../constants/Utils";
import { WIDTH } from "../../constants/Layout";
import { find } from "lodash";
import UserForm from "../UserForm/UserForm";

import styles from "./Menu.module.css";

class CustomMenu extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeItem: "",
			modalActive: false,
		};
	}

	componentDidMount = () => {
		const { pathname } = this.props.location;
		const item = find(this.props.items, { route: pathname });
		if (item) {
			return this.setState({ activeItem: item.name });
		}
	};

	handleLogout = (e) => {
		e.preventDefault();
		this.props.dispatch(logout());
	};

	handleItemClick = (e, name, route) => {
		e.preventDefault();
		e.stopPropagation();
		if (window.location.pathname !== route) {
			this.setState({ activeItem: name });
			this.props.dispatch(navigate(route));
		}
	};

	openModal = () => {
		this.setState({ modalActive: true });
	};

	closeModal = () => {
		this.setState({ modalActive: false });
	};

	getAccountType = (role) => {
		switch (role) {
			case "User":
				return getLabel("Player");
			case "Super":
				return getLabel("Super");
			case "Admin":
				return getLabel("Admin");
			case "Detaillants":
				return getLabel("Seller");
			case "Distributeurs":
				return getLabel("Dealer");
			case "Investor":
				return getLabel("Investor");
			default:
				return "";
		}
	};

	renderMenu = (menu, cart) => {
		if (menu !== undefined) {
			const { activeItem } = this.state;
			const menuItems = menu.map((item, index) => {
				const active = activeItem === item.name;
				if (item.name === "Cart") {
					return (
						<Menu.Item
							key={index}
							index={index}
							className={active ? styles.activeMenuItem : styles.menuItem}
							name={getLabel(item.name)}
							active={active}
							disabled={active}
							onClick={(e) => this.handleItemClick(e, item.name, item.route)}
						>
							<Icon name={item.icon} className={styles.menuIcon} />
							<div>{getLabel(item.name)}</div>
							<Label color={"red"}>{cart.items.length || 0}</Label>
						</Menu.Item>
					);
				}
				return (
					<Menu.Item
						key={index}
						index={index}
						className={active ? styles.activeMenuItem : styles.menuItem}
						name={getLabel(item.name)}
						active={active}
						disabled={active}
						onClick={(e) => this.handleItemClick(e, item.name, item.route)}
					>
						<Icon name={item.icon} className={styles.menuIcon} />
						<div>{getLabel(item.name)}</div>
					</Menu.Item>
				);
			});
			return menuItems;
		}
		return null;
	};

	render() {
		const { items, toggleMenu, navigation, user, cart, location } = this.props;
		const { nom, surnom, tel, email, credit, role } = user;
		const account = this.getAccountType(role);

		if (navigation.navigate && location.pathname !== navigation.route) {
			return <Redirect push to={navigation.route} />;
		}

		return (
			<React.Fragment>
				<UserForm visible={this.state.modalActive} onClose={this.closeModal} />
				<Menu vertical className={styles.menuContainer}>
					<Menu.Item className={styles.profileContainer}>
						<div className={styles.logo}>
							<div className={styles.backIcon} onClick={toggleMenu}>
								<Icon name={"chevron left"} size={"large"} />
							</div>
							<div className={styles.editIcon} style={{ left: WIDTH * 0.65 }} onClick={this.openModal}>
								<Icon name={"edit outline"} size={"large"} />
							</div>
							<Image src={"/logo.png"} size={"small"} centered />
						</div>
						<div className={styles.profile}>
							<div className={styles.profileTextContainer}>
								<div className={styles.profileText} style={{ display: role === "User" ? "flex" : "none" }}>
									{surnom}
								</div>
								<div className={styles.profileText}>{nom}</div>
								<div className={styles.profileSubText}>{phoneFormat(tel)}</div>
								<div className={styles.profileSubText} style={{ display: role === "User" ? "none" : "flex" }}>
									{email}
								</div>
								<div className={styles.profileSubText}>{getLabel("AccountType") + ": " + account}</div>
							</div>
							<div className={styles.profileBalanceContainer}>
								<div className={styles.profileBalanceText}>{getLabel("Balance") + ": " + currencyFormat(credit)}</div>
							</div>
						</div>
					</Menu.Item>
					{this.renderMenu(items, cart)}
					<Menu.Item className={styles.menuButtonContainer} onClick={this.handleLogout}>
						<div className={styles.menuButton}>
							<Icon name={"log out"} className={styles.menuIcon} />
							<div>{getLabel("SignOut")}</div>
						</div>
					</Menu.Item>
				</Menu>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ user, navigation, cart, settings }) {
	return { user, navigation, cart, settings };
}

export default withRouter(connect(mapStateToProps)(CustomMenu));
