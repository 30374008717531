import { GET_CITIES, GET_ZONES } from "../actions/types";

export default function city(state = { cities: [], zones: [] }, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CITIES:
			return { ...state, cities: payload.data };

		case GET_ZONES:
			return { ...state, zones: payload.data[0].zone };

		default:
			return state;
	}
}
