import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import { endNavigate } from "../../redux/actions/navigation";
import { createInvestor } from "../../redux/actions/user";
import { getCities } from "../../redux/actions/city";
import Container from "../../components/Container/Container";
import PasswordInput from "../../components/PasswordInput/PasswordInput";

import Input from "../../components/Input/Input";
import styles from "./CreateInvestorScreen.module.css";

class CreateInvestorScreen extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			email: "",
			percent: "",
			phone: "",
			address: "",
			password: "",
		};

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = () => {
		this.props.dispatch(getCities());
	};

	handleSignUp = async () => {
		const { name, email, phone, address, password, percent } = this.state;

		//Replace CreateAdmin by Create Investor
		await this.props.dispatch(createInvestor(name, email, phone, address, password, percent));
		this.setState({
			name: "",
			email: "",
			phone: "",
			address: "",
			password: "",
			percent: "",
		});
	};

	onInputChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleDropdownChange = (e, { value }) => {
		e.preventDefault();
		this.setState({ city: value });
	};

	render() {
		const { name, percent, phone, address, password, email } = this.state;
		return (
			<Container title={getLabel("CreateInvestor")}>
				<div className={styles.inputsContainer}>
					<Input
						icon={"user"}
						name={"name"}
						value={name}
						placeholder={getLabel("FullName")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"mail"}
						name={"email"}
						value={email}
						placeholder={getLabel("Email")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"phone"}
						name={"phone"}
						type={"number"}
						value={phone}
						placeholder={getLabel("Phone")}
						onChange={this.onInputChange}
					/>

					<Input
						icon={"percent"}
						name={"percent"}
						type={"number"}
						value={percent}
						placeholder={getLabel("Percent")}
						onChange={this.onInputChange}
					/>
					<Input
						icon={"map"}
						name={"address"}
						value={address}
						placeholder={getLabel("Address")}
						onChange={this.onInputChange}
					/>
					<PasswordInput
						icon={"lock"}
						name={"password"}
						value={password}
						placeholder={getLabel("Password")}
						onChange={this.onInputChange}
					/>
				</div>
				<div className={styles.buttonsContainer}>
					<Button className={styles.button} onClick={this.handleSignUp}>
						<Icon name={"user plus"} />
						<div className={styles.text}>{getLabel("CreateInvestor")}</div>
					</Button>
				</div>
			</Container>
		);
	}
}

function mapStateToProps({ user, navigation, city }) {
	return { user, navigation, city };
}

export default connect(mapStateToProps)(CreateInvestorScreen);
