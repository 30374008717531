import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import { API_FAILURE, GET_CITIES, GET_ZONES } from "./types";
import { getCurrentLanguage } from "../../constants/Utils";

function requestSuccess(type, data) {
	return { type, payload: { data } };
}

function requestFail(errorCode, language) {
	return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getCities() {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.get("/getVille")
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(GET_CITIES, response.data.data));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}

export function getZones(city) {
	return dispatch => {
		const language = getCurrentLanguage();
		dispatch(apiStart());
		Api.post("/getZone", {
			ville: city
		})
			.then(response => {
				if (response.data.success) {
					dispatch(requestSuccess(GET_ZONES, response.data.data));
					dispatch(apiEnd());
				} else {
					dispatch(apiEnd());
					dispatch(requestFail(response.data.message, language));
				}
			})
			.catch(error => {
				dispatch(apiError(error, language));
				dispatch(apiEnd());
			});
	};
}
