import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getLabel } from "../../constants/Strings";
import { getListDraws } from "../../redux/actions/draw";
import { endNavigate } from "../../redux/actions/navigation";
import { Button, Icon } from "semantic-ui-react";
import { getBoulcashDetail } from "../../redux/actions/boulpik";
import DropdownDraw from "../../components/DropdownDraw/DropdownDraw";
import Container from "../../components/Container/Container";
//import Calendar from "../../components/Calendar/Calendar";
//import Input from "../../components/Input/Input";
import BoulpikInput from "../../components/BoulpikInput/BoulpikInput";

import styles from "./WinnerDetailScreen.module.css";
import BoulcashDetailItem from "../../components/BoulcashDetail/BoulcashDetailItem";


class WinnerDetailScreen extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dateOfDraw: "",
      initial: "",
      modalVisible: false,
      boulpik:"",
      data:[]
    };

    if (this.props.navigation.navigate) {
      this.props.dispatch(endNavigate());
    }
    window.scrollTo(0, 0);
  }

 

  handleDropdownChange = (e, { value }) => {
    e.preventDefault();
    this.setState({ dateOfDraw: value });
  };

  componentDidMount = async () => {
    await this.props.dispatch(getListDraws());
  };

  processDraws = (draw) => {
    let dates = [];
    if (draw !== undefined) {
      draw.forEach((item) => {
        const dateString = item.end.split("/");
        const dd = dateString[0];
        const mm = dateString[1] - 1;
        const yyyy = dateString[2];
        const newDate = new Date(yyyy, mm, dd);
        dates.push(newDate);
      });
      return dates;
    }
    return null;
  };

  handleDrawChange = (e, { value }) => {
    this.setState({ value });
  };

  onInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDetailsBoulcash = async () => {
    const {boulpik,dateOfDraw } = this.state;
    await this.props.dispatch(getBoulcashDetail(boulpik, dateOfDraw));
    this.setState({modalVisible: true});
  };
  
  handleTextChange = (e) => {
		e.preventDefault();
		const { value } = e.target;
		if (!isNaN(value) && value.length <= 5) {
			return this.setState({ boulpik: value });
      
		}
	};

  renderItemList = data => {
		const List = data?.map((item, index) => {
			return (
				<BoulcashDetailItem
					key={index}
					tirageDate={item?.tirageDate}
					totalPlayed={item?.totalPlayed}
          username={item?.username}
          tel={item?.tel}
          boulcash={item?.boulcash}
					price={item?.price}
          ville= {item?.ville}
          nickname = {item?.nickname}
				/>
			);
		});
		return List;
	};


  

  render() {
    const { draw } = this.props;
    const { modalVisible,dateOfDraw, boulpik } = this.state;
    const disabled = boulpik.length === 5;
    const data  = this.props.boulpik.info ?? []; 
    return (
      <Container title={getLabel("DetailsBoulcash")}>
        {/* <Calendar markedDates={this.processDraws(draw.draws)} value={value} onChange={this.handleDrawChange} /> */}
        <div className={styles.inputsContainer}>
          <DropdownDraw
            options={draw.draws}
            icon={"calendar"}
            placeholder={getLabel("Draw")}
            value={dateOfDraw}
            onChange={this.handleDropdownChange}
          />
          <BoulpikInput value={this.state?.boulpik} onChange={this.handleTextChange} />
        </div>

        {modalVisible && <div className={styles.cartItems}>{this.renderItemList(data ?? [])}</div>}

        {disabled && dateOfDraw.length>0 && <Button className={styles.button} onClick={this.handleDetailsBoulcash} disabled={!disabled}>
          <Icon name={"search"} />
          <div className={styles.text}>{getLabel("Ok")}</div>
        </Button>}

       


      </Container>


    );
  }
}

function mapStateToProps({ draw, navigation, boulpik }) {
  return { draw, navigation,boulpik };
}

export default connect(mapStateToProps)(WinnerDetailScreen);
