import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";
import styles from "./PasswordInput.module.css";

class PasswordInput extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			type: "password"
		};
	}

	handleShowHide = event => {
		event.preventDefault();
		event.stopPropagation();
		this.setState({
			type: this.state.type === "input" ? "password" : "input"
		});
		this.focus();
	};

	handleRef = c => {
		this.inputRef = c;
	};

	focus = () => {
		this.inputRef.focus();
	};

	render() {
		const { name, value, placeholder, onChange, icon, disabled, style } = this.props;
		let pic = this.state.type === "password" ? "eye slash" : "eye";

		return (
			<div className={styles.inputContainer} style={style}>
				<Icon name={icon} className={styles.icon} />
				<input
					disabled={disabled}
					className={styles.input}
					ref={this.handleRef}
					type={this.state.type}
					placeholder={placeholder}
					name={name}
					value={value}
					onChange={onChange}
				/>
				<Icon name={pic} className={styles.iconInverted} onClick={this.handleShowHide} />
			</div>
		);
	}
}

export default PasswordInput;
