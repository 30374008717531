import { GET_DRAW, CREATE_DRAW, GET_DETAIL_DRAW} from "../actions/types";

export default function draw(state = { detailsdraws: [], draws: [] }, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DRAW:
      return { ...state, draws: payload.data };

    case GET_DETAIL_DRAW:
      return { ...state, detailsdraws: payload.data };

    case CREATE_DRAW:
      return { ...state, draws: state.draws.concat(payload.data) };

    default:
      return state;
  }
}
