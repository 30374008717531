import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { getLabel } from "../../constants/Strings";
import Container from "../../components/Container/Container";
import { deleteFromCart, buyAllFromCart, getCart, buyOneFromCart } from "../../redux/actions/cart";
import { getDetailsDraws } from "../../redux/actions/draw";
import { endNavigate } from "../../redux/actions/navigation";
import { currencyFormat } from "../../constants/Utils";
import { reduce } from "lodash";
import DetailDrawItem from "../../components/DetailDrawItem/DetailDrawItem";


import styles from "./DrawDetailsScreen.module.css";

class CartScreen extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalVisible: false,
			date: "",
			boulpik: ""
		};

		if (this.props.navigation.navigate) {
			this.props.dispatch(endNavigate());
		}
		window.scrollTo(0, 0);
	}

	componentDidMount = async () => {
		await this.props.dispatch(getCart(this.props.user._id));
    await this.props.dispatch(getDetailsDraws());
	};

	handleBuyOne = async (date, boulpik, price) => {
		const cartItem = { fecha: date, boulpik, price };
		await this.props.dispatch(buyOneFromCart(cartItem));
	};

	handleBuyAll = async () => {
		await this.props.dispatch(buyAllFromCart(this.props.cart.items));
	};

	handleDeleteItem = async () => {
		const { date, boulpik } = this.state;
		await this.props.dispatch(deleteFromCart(date, boulpik));
		this.setState({ modalVisible: false });
	};

	handleOpenModal = (date, boulpik) => {
		this.setState({ modalVisible: true, date, boulpik });
	};

	handleCloseModal = () => {
		this.setState({ modalVisible: false });
	};

	renderItemsTotal = data => {
		const total = reduce(
			data,
			function(sum, item) {
				return sum + parseInt(item.price);
			},
			0
		);
		return total;
	};

	renderItemList = data => {
		const List = data?.map((item, index) => {
			return (
				<DetailDrawItem
					key={index}
					tirageDate={item?.fecha}
					totalPlayed={item?.totalBoulcahPlayed}
          countPlayer={item?.totalUser}
          maxBought={item?.max}
          playerName={item?.user}
					price={item?.price}
				/>
			);
		});
		return List;
	};

	render() {
		const { draw } = this.props;

		return (
			<Container title={getLabel("DrawDetails")}>
				<Modal size="small" open={this.state.modalVisible} onClose={this.handleCloseModal}>
					<Modal.Header className={styles.modalHeader}>{getLabel("Delete")}</Modal.Header>
					<Modal.Content>
						<p className={styles.text}>{getLabel("AreYouSure")}</p>
					</Modal.Content>
					<Modal.Actions className={styles.modalActions}>
						<Button
							className={styles.actionButton}
							negative
							icon="cancel"
							labelPosition="right"
							content={getLabel("Cancel")}
							onClick={this.handleCloseModal}
						/>
						<Button
							className={styles.actionButton}
							positive
							icon="checkmark"
							labelPosition="right"
							content={getLabel("Ok")}
							onClick={this.handleDeleteItem}
						/>
					</Modal.Actions>
				</Modal>

				<div className={styles.checkoutContainer}>
					<div className={styles.checkoutInfoContainer}>
						<div className={styles.cartInfo}>
							<div className={styles.titleText}>{getLabel("Draw")}</div>
							<div className={styles.text}>{draw?.detailsdraws?.responseF?.length + " " + getLabel("Items")}</div>
						</div>
						<div className={styles.checkoutInfo}>
							<div className={styles.titleText}>{currencyFormat(draw?.detailsdraws?.balanceTotal)}</div>
							<div className={styles.text}>{getLabel("Total")}</div>
						</div>
					</div>
				</div>
				<div className={styles.cartItems}>{this.renderItemList(draw?.detailsdraws?.responseF)}</div>
			</Container>
		);
	}
}

function mapStateToProps({ user, cart, draw, navigation }) {
	return { user, cart, navigation, draw };
}

export default connect(mapStateToProps)(CartScreen);
