import React from "react";
import styles from "../../components/PrintComponent/PrintComponent.module.css";
import { getLabel } from "../../constants/Strings";
import { currencyFormat, dateFormat } from "../../constants/Utils";
import { Image } from "semantic-ui-react";

import QRCode from "react-qr-code";

export default class MyPrintableComponent extends React.Component {
	render() {
		const item = this.props.item;

		return (
			<div id={this.props.printableId}>
				<Image src={"/logo.png"} size={"small"} centered className={styles.spacer} />
				{/* <div className={styles.row}>
          <div className={styles.text}>{getLabel("Initial")}</div>
          <div className={styles.text}>{currencyFormat(item.initial)}</div>
        </div> */}
				<div className={styles.row}>
					<div centered className={styles.text}>
						{getLabel("Seller")}
					</div>
					<div centered className={styles.text}>
						{item.nom}
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.text}>{getLabel("BoulpikPlayed")}</div>
					<div className={styles.text}>{item.boulpik}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Prix")}</div>
					<div className={styles.text}>{currencyFormat(item.date)}</div>
				</div>
				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Tirage")}</div>
					<div className={styles.text}>{item.date}</div>
				</div>

				<div className={styles.row}>
					<div className={styles.text}>{getLabel("Dates")}</div>
					<div className={styles.text}>{dateFormat(item.today)}</div>
				</div>
				<div centered className={styles.qrcode}>
					<QRCode size={120} value={item.userId} />
				</div>
			</div>
		);
	}
}
